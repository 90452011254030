import * as DeliveryOption from '@retail/order-constants/src/deliveryOptions/enum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const DELIVERY_OPTION_TRANSLATION_KEY =
  'bo.orderOverview.deliveryOptions.';

export function useDeliveryOptions() {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: DeliveryOption.AH_TRUCK,
        label: t(`${DELIVERY_OPTION_TRANSLATION_KEY}${DeliveryOption.AH_TRUCK}`)
      },
      {
        value: DeliveryOption.AH_TRUCK_EXPRESS,
        label: t(
          `${DELIVERY_OPTION_TRANSLATION_KEY}${DeliveryOption.AH_TRUCK_EXPRESS}`
        )
      },
      {
        value: DeliveryOption.AH_JOCKEY,
        label: t(
          `${DELIVERY_OPTION_TRANSLATION_KEY}${DeliveryOption.AH_JOCKEY}`
        )
      },
      {
        value: DeliveryOption.THIRD_PARTY_TRUCK,
        label: t(
          `${DELIVERY_OPTION_TRANSLATION_KEY}${DeliveryOption.THIRD_PARTY_TRUCK}`
        )
      },
      {
        value: DeliveryOption.THIRD_PARTY_JOCKEY,
        label: t(
          `${DELIVERY_OPTION_TRANSLATION_KEY}${DeliveryOption.THIRD_PARTY_JOCKEY}`
        )
      },
      {
        value: DeliveryOption.NEAREST_BRANCH,
        label: t(
          `${DELIVERY_OPTION_TRANSLATION_KEY}${DeliveryOption.NEAREST_BRANCH}`
        )
      },
      {
        value: DeliveryOption.NEAREST_BRANCH_EXPRESS,
        label: t(
          `${DELIVERY_OPTION_TRANSLATION_KEY}${DeliveryOption.NEAREST_BRANCH_EXPRESS}`
        )
      },
      {
        value: DeliveryOption.MIN_ETA,
        label: t(`${DELIVERY_OPTION_TRANSLATION_KEY}${DeliveryOption.MIN_ETA}`)
      },
      {
        value: DeliveryOption.MIN_ETA_EXPRESS,
        label: t(
          `${DELIVERY_OPTION_TRANSLATION_KEY}${DeliveryOption.MIN_ETA_EXPRESS}`
        )
      }
    ],
    [t]
  );
}
