import { OptionModel } from '@retail/backoffice-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { WarrantyLevelType } from '~/apollo/gql-types';
import { MonthsCoveredType } from '~/constants/MonthsCoveredType';

export function useWarrantyOptions() {
  const { t } = useTranslation();

  return useMemo(
    (): OptionModel[] => [
      {
        value: WarrantyLevelType.Basic,
        label: t(`bo.orderOverview.searchForm.field.warranty.BASIC`)
      },
      {
        value: MonthsCoveredType.Low,
        label: t(`bo.orderOverview.searchForm.field.warranty.PREMIUM`, {
          monthsCovered: MonthsCoveredType.Low
        })
      },
      {
        value: MonthsCoveredType.Middle,
        label: t(`bo.orderOverview.searchForm.field.warranty.PREMIUM`, {
          monthsCovered: MonthsCoveredType.Middle
        })
      },
      {
        value: MonthsCoveredType.High,
        label: t(`bo.orderOverview.searchForm.field.warranty.PREMIUM`, {
          monthsCovered: MonthsCoveredType.High
        })
      }
    ],
    [t]
  );
}
