import { FormItem } from '@retail/backoffice-ui';
import {
  ColProps,
  Input,
  InputNumber,
  InputNumberProps,
  InputProps
} from 'antd';
import { ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';

import cn from './styles.less';

const defaultFormat = (value: string | number) => value;
const defaultParse = (value) => value;

type Props<T> = {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  labelCol?: ColProps;
  required?: boolean;
  disabled?: boolean;
  qaSelector?: string;
  parse?: (value) => string | number;
  format?: (value) => string | number;
} & (InputProps & InputNumberProps);

export function InputControlled<T extends FieldValues>({
  required,
  disabled,
  label,
  controllerProps,
  format = defaultFormat,
  parse = defaultParse,
  type,
  qaSelector,
  labelCol,
  addonBefore,
  className,
  onChange,
  ...restInputProps
}: Props<T>) {
  const { field } = useController(controllerProps);

  return (
    <FormItem
      label={label}
      labelCol={labelCol}
      required={required}
      disabled={disabled}
      className={className}
      controllerProps={controllerProps as unknown as UseControllerProps}
    >
      <div className={cn.inputWrapper}>
        {addonBefore && <div className={cn.addonBefore}>{addonBefore}</div>}
        {type === 'number' ? (
          <InputNumber
            {...field}
            disabled={disabled}
            data-qa-selector={qaSelector}
            value={format(field.value)}
            onChange={(value, ...rest) => {
              field.onChange(parse(value));
              onChange?.(value, ...rest);
            }}
            {...restInputProps}
            decimalSeparator=","
          />
        ) : (
          <Input
            disabled={disabled}
            data-qa-selector={qaSelector}
            autoComplete="off"
            type={type}
            {...field}
            value={format(field.value)}
            onChange={(value, ...rest) => {
              field.onChange(parse(value));
              onChange?.(value, ...rest);
            }}
            {...restInputProps}
          />
        )}
      </div>
    </FormItem>
  );
}
