import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useLocalizedOptions(
  options: { value: string; label: string }[]
) {
  const { t } = useTranslation();

  return useMemo(
    () => options.map(({ label, ...opt }) => ({ ...opt, label: t(label) })),

    [options, t]
  );
}
