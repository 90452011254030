import { useTranslation } from 'react-i18next';

import { getUniqueValues } from './utils';

interface Props {
  value?: Record<string, Record<string, unknown>>;
}

export function Tires({ value }: Props) {
  const { t } = useTranslation();
  const uniqueValues = getUniqueValues(value);

  return (
    <>
      {uniqueValues.map(
        ([
          key,
          { seasonType, width, heightRatio, radius, manufacturer, treadDepth }
        ]) => (
          <div key={key}>
            {`${
              uniqueValues.length > 1
                ? `${t(
                    `bo.summaryVerificationSecondaryWheelsUpsell.table.column.key.${key}`
                  )}: `
                : ''
            }${[
              seasonType
                ? t(
                    `bo.summaryVerificationSecondaryWheelsUpsell.table.column.seasonType.${seasonType}`
                  )
                : null,
              [width, heightRatio].filter(Boolean).join('/'),
              `R${radius}`,
              manufacturer,
              t(
                'bo.summaryVerificationSecondaryWheelsUpsell.table.column.depth',
                { treadDepth }
              )
            ]
              .filter(Boolean)
              .join('. ')}`}
          </div>
        )
      )}
    </>
  );
}
