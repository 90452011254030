import { useMemo } from 'react';

import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { useUser } from '~/hooks/useUser';

import { getGQLPermissions, hasPermissions } from './utils';

export interface UseCheckPermissionsProps {
  allow: PERMISSION | PERMISSION[];
  domain: DOMAIN | DOMAIN[];
}

interface UseCheckPermissionsResult {
  isAllowed: boolean;
  isDenied: boolean;
  isLoading: boolean;
}

export function useCheckPermissions({
  allow,
  domain
}: UseCheckPermissionsProps): UseCheckPermissionsResult {
  const permissionsData = useUser();

  return useMemo(() => {
    const { permissions, isLoaded } = permissionsData;
    const requestedPerms = getGQLPermissions(allow, domain);
    const isValid = hasPermissions(permissions, requestedPerms);

    return {
      isAllowed: isLoaded && isValid,
      isDenied: isLoaded && !isValid,
      isLoading: !isLoaded
    };
  }, [allow, domain, permissionsData]);
}
