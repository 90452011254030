import { OptionModel } from '@retail/backoffice-ui';
import { CUSTOMER_TYPES_VALUES } from '@retail/order-constants/src/customerTypes';
import { useTranslation } from 'react-i18next';

export const CUSTOMER_TYPE_KEY_PREFIX =
  'bo.orderOverview.searchForm.options.customerTypes';

export function useCustomerTypeOptions() {
  const { t } = useTranslation('', { keyPrefix: CUSTOMER_TYPE_KEY_PREFIX });

  return CUSTOMER_TYPES_VALUES.map(
    (value): OptionModel => ({ value, label: t(value) })
  );
}
