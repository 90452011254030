import { useMemo } from 'react';
import { getConf } from '@retail/config';
import { getDefaultLocaleOfCountry } from '@retail/i18n/utils';

import { useRetailFeaturesQuery } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { FEATURE } from '~/constants/feature';

const FEATURES_ENV = getConf('FEATURES_ENV');

type FeaturesAsyncParams = {
  names: FEATURE[];
  country: string | null;
  featureUsageDate?: string;
};

export const useFeatures = ({
  names,
  country,
  featureUsageDate
}: FeaturesAsyncParams) => {
  const { data, loading: isLoading } = useRetailFeaturesQuery({
    ...REQUEST_BASE_OPTIONS,
    fetchPolicy: 'cache-and-network',
    skip: !country,
    variables: {
      names,
      context: {
        environment: FEATURES_ENV,
        country,
        locale: country ? getDefaultLocaleOfCountry(country) : null,
        featureUsageDate
      }
    }
  });

  const isFeatureEnabled = useMemo(() => {
    const featuresSet = new Map<FEATURE, boolean>();
    data?.features?.forEach(({ name, enabled }) =>
      featuresSet.set(name as FEATURE, enabled)
    );

    return (feature: FEATURE) => featuresSet.get(feature);
  }, [data]);

  return {
    isLoading,
    isFeatureEnabled
  };
};
