import getFileExtension from 'file-extension';

import { UserProjection } from '~/apollo/gql-types';

export const DOCUMENT_FILE_TYPES = [
  { ext: 'jpg', mime: 'image/jpeg' },
  { ext: 'jpeg', mime: 'image/jpeg' },
  { ext: 'png', mime: 'image/png' },
  { ext: 'heic', mime: 'image/heic' },
  { ext: 'pdf', mime: 'application/pdf' }
];

export const getMimeTypeByExtension = (ext: string) =>
  DOCUMENT_FILE_TYPES.find((type) => type.ext === ext)?.mime;

export function getFileNameFromUrl(url: string) {
  if (!url) {
    return;
  }

  try {
    const { pathname } = new global.URL(url);

    return pathname.substring(pathname.lastIndexOf('/') + 1);
  } catch (error) {
    console.error(error);
  }
}

export const isImageMimeType = (mimeType: string) =>
  mimeType.includes('image/');

interface Props {
  fullUrl: string;
  fileName: string;
}

export interface ReturnGetFileMeta {
  fileName: string;
  fileExtension: string;
  mimeType: string;
}

export function getFileMeta(params: Props): ReturnGetFileMeta {
  const fileName = params.fileName || getFileNameFromUrl(params.fullUrl);
  const fileExtension = fileName ? getFileExtension(fileName) : undefined;

  return {
    fileName,
    fileExtension,
    mimeType: fileExtension ? getMimeTypeByExtension(fileExtension) : undefined
  };
}

export function getFullName(value: Partial<UserProjection>) {
  const { firstName, lastName } = value ?? {};

  return [firstName, lastName].filter(Boolean).join(' ');
}
