import { PlusOutlined } from '@ant-design/icons';
import { dispatchEvent } from '@retail/backoffice-events';
import { Button, Popconfirm, notification } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import { useTranslation } from 'react-i18next';

import { useUpsellSecondaryWheelsMutation } from '~/apollo/gql-types';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { useCheckPermissions } from '~/hooks/useCheckPermissions';

interface Props {
  orderId?: string;
  secondaryWheelsId?: string;
}

export function ActionButton({ orderId, secondaryWheelsId }: Props) {
  const { t } = useTranslation();
  const [onSubmit, { loading }] = useUpsellSecondaryWheelsMutation({
    variables: {
      orderId,
      secondaryWheelsId
    },
    onCompleted() {
      notification.success({
        message: t(
          'bo.summaryVerificationSecondaryWheelsUpsell.table.action.successMessage'
        )
      });
      dispatchEvent('orderVerification.secondaryWheelsUpsell.updated', null);
    },
    refetchQueries: ['SecondaryWheelsUpsellOptions'],
    awaitRefetchQueries: true
  });
  const { isAllowed } = useCheckPermissions({
    allow: [PERMISSION.UPSELL_SECONDARY_WHEELS],
    domain: DOMAIN.ORDER_MANAGEMENT
  });

  return isAllowed ? (
    <Popconfirm
      placement="top"
      title={t(
        'bo.summaryVerificationSecondaryWheelsUpsell.table.confirmation.message'
      )}
      onConfirm={() => onSubmit()}
      okText={t('bo.common.yes')}
      cancelText={t('bo.common.no')}
      okButtonProps={
        {
          ['data-qa-selector']: 'okSecondaryWheelsUpsellButton'
        } as BaseButtonProps
      }
    >
      <Button
        type="primary"
        loading={loading}
        data-qa-selector="secondaryWheelsUpsellButton"
        icon={<PlusOutlined />}
      >
        {t('bo.summaryVerificationSecondaryWheelsUpsell.table.action.button')}
      </Button>
    </Popconfirm>
  ) : null;
}
