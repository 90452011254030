import { WarningOutlined } from '@ant-design/icons';
import { TableCell } from '@retail/backoffice-ui';
import { Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ColorCoding } from '~/apollo/gql-types';
import { OrderState } from '~/constants/OrderState';
import { NAMESPACE } from '~/i18n/constants';

import { CanceledOrderStateTooltip } from './CanceledOrderStateTooltip';
import { CancellationRequestedOrderStateTooltip } from './CancellationRequestedOrderStateTooltip';

interface Props {
  value: OrderState;
  cancelType: string;
  cancelPeriod: string;
  cancelReason: string;
  subCancelReason: string;
  cancelDescription: string;
  stateColorCoding: ColorCoding;
  documentRequests: number;
}

export function OrderStateCell({
  value,
  cancelType,
  cancelPeriod,
  cancelReason,
  subCancelReason,
  cancelDescription,
  stateColorCoding,
  documentRequests
}: Props) {
  const { t: labelT } = useTranslation(NAMESPACE, {
    keyPrefix: 'bo.taskManagement.orderStates'
  });
  const label = labelT(value);
  const { t } = useTranslation();

  return (
    <TableCell data-qa-selector="state">
      <Space direction="horizontal" size="small">
        {label}
        {value === OrderState.CANCELED ? (
          <CanceledOrderStateTooltip
            cancelType={cancelType}
            cancelPeriod={cancelPeriod}
            cancelReason={cancelReason}
            subCancelReason={subCancelReason}
            cancelDescription={cancelDescription}
          />
        ) : (
          <CancellationRequestedOrderStateTooltip
            stateColorCoding={stateColorCoding}
          />
        )}
        {documentRequests ? (
          <Tooltip
            title={t('bo.orderOverview.table.orderStateWarning', {
              quantity: documentRequests
            })}
          >
            <WarningOutlined />
          </Tooltip>
        ) : null}
      </Space>
    </TableCell>
  );
}
