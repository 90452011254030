import { FormItem } from '@retail/backoffice-ui';
import React, { ReactNode } from 'react';
import { ColProps, Input, InputProps } from 'antd';
import { ParseError, parsePhoneNumberWithError } from 'libphonenumber-js';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface PhoneInputProps extends Omit<InputProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
}

const prepareValue = (value: string) => {
  const cleanValue = value.replace(/[^0-9]+/g, '');
  return `+${cleanValue}`;
};

const PhoneInput = (props: PhoneInputProps) => {
  const { value, onChange, ...restProps } = props;

  const handleChange = (value: string) => onChange?.(prepareValue(value));

  return (
    <Input
      {...restProps}
      type="phone"
      value={prepareValue(value ?? '')}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

const isValidPhoneNumber = (value: string) => {
  try {
    const phone = parsePhoneNumberWithError(value);
    return phone.isPossible();
  } catch (e) {
    if (e instanceof ParseError) {
      return false;
    }
    throw e;
  }
};

interface PhoneInputControlledProps<T extends FieldValues>
  extends Omit<PhoneInputProps, 'value' | 'onChange'> {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  labelCol?: ColProps;
  required?: boolean;
  disabled?: boolean;
  qaSelector?: string;
}

export const PhoneInputControlled = <T extends FieldValues>({
  label,
  labelCol,
  required,
  disabled,
  qaSelector,
  controllerProps: outerControllerProps,
  ...rest
}: PhoneInputControlledProps<T>) => {
  const { t } = useTranslation();

  const controllerProps: UseControllerProps<T> = {
    ...outerControllerProps,
    rules: {
      ...(outerControllerProps.rules ?? {}),
      validate: (value) => {
        if (!isValidPhoneNumber(value)) {
          return t('bo.common.form.field.invalid');
        }
      }
    }
  };

  const { field } = useController(controllerProps);

  return (
    <FormItem
      label={label}
      labelCol={labelCol}
      required={required}
      disabled={disabled}
      controllerProps={controllerProps as unknown as UseControllerProps}
    >
      <>
        <PhoneInput
          {...rest}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          disabled={disabled}
          data-qa-selector={qaSelector}
        />
      </>
    </FormItem>
  );
};
