import { PlusOutlined } from '@ant-design/icons';
import { dispatchEvent } from '@retail/backoffice-events';
import { Button, Popconfirm, notification } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import { useTranslation } from 'react-i18next';

import { useUpsellTradeInMutation } from '~/apollo/gql-types';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { useCheckPermissions } from '~/hooks/useCheckPermissions';

interface Props {
  orderId?: string;
  tradeInId?: string;
}

export function ActionButton({ orderId, tradeInId }: Props) {
  const { t } = useTranslation();
  const [onSubmit, { loading }] = useUpsellTradeInMutation({
    variables: {
      orderId,
      tradeInId
    },
    onCompleted() {
      notification.success({
        message: t(
          'bo.summaryVerificationTradeInUpsell.table.submit.successMessage'
        )
      });
      dispatchEvent('orderVerification.tradeInUpsell.updated', null);
    },
    refetchQueries: ['TradeInUpsellOptions'],
    awaitRefetchQueries: true
  });
  const { isAllowed } = useCheckPermissions({
    allow: PERMISSION.UPSELL_TRADE_IN,
    domain: DOMAIN.ORDER_MANAGEMENT
  });

  return isAllowed ? (
    <Popconfirm
      placement="top"
      title={t(
        'bo.summaryVerificationTradeInUpsell.table.confirmation.message'
      )}
      disabled={!tradeInId}
      onConfirm={() => onSubmit()}
      okText={t('bo.common.yes')}
      cancelText={t('bo.common.no')}
      okButtonProps={
        {
          ['data-qa-selector']: 'okTradeInUpsellButton'
        } as BaseButtonProps
      }
    >
      <Button
        type="primary"
        disabled={!tradeInId}
        loading={loading}
        data-qa-selector="tradeInUpsellButton"
        icon={<PlusOutlined />}
      >
        {t('bo.summaryVerificationTradeInUpsell.table.action.button')}
      </Button>
    </Popconfirm>
  ) : null;
}
