import classNames from 'classnames';

import { OrderState } from '~/constants/OrderState';
import { EsOrderModel } from '~/types/EsOrderModel';

import cn from './styles.less';

const LIMIT_INDICATOR = 1;

export function priorityClassName({ priority, state }: EsOrderModel) {
  if (state !== OrderState.PENDING_VERIFICATION) {
    return;
  }

  return {
    className: classNames({
      [cn.greenRow]: priority === LIMIT_INDICATOR,
      [cn.yellowRow]: priority > LIMIT_INDICATOR
    })
  };
}
