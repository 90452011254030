// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bSx_WM_7Feaz8ChNu07T {\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/components/OrderNumber/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".text {\n  white-space: nowrap;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "bSx_WM_7Feaz8ChNu07T"
};
export default ___CSS_LOADER_EXPORT___;
