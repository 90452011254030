import { notification } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCreateCustomerUserMutation,
  useUpdateCustomerUserMutation
} from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { AUX_USER_REGISTERED_FROM_BACK_OFFICE } from '~/constants/auxUser/registeredFrom';

import { CustomerFormVM } from './CustomerForm';

export const useSaveCustomer = (customerId?: string) => {
  const onCustomerSaved = () =>
    notification.success({
      message: t('bo.orderCreate.customerSavedSuccessfully')
    });

  const [updateCustomerUserMutation, updateMutationOptions] =
    useUpdateCustomerUserMutation({
      ...REQUEST_BASE_OPTIONS,
      onCompleted: onCustomerSaved
    });
  const [createCustomerUserMutation, createMutationOptions] =
    useCreateCustomerUserMutation({
      ...REQUEST_BASE_OPTIONS,
      onCompleted: onCustomerSaved
    });

  const { t } = useTranslation();
  const saveCustomer = useCallback(
    async (data: CustomerFormVM) =>
      customerId
        ? (
            await updateCustomerUserMutation({
              variables: {
                id: customerId,
                user: JSON.parse(JSON.stringify({ ...data, email: undefined }))
              }
            })
          )?.data?.customer?.id
        : (
            await createCustomerUserMutation({
              variables: {
                user: {
                  ...data,
                  registeredFrom: AUX_USER_REGISTERED_FROM_BACK_OFFICE
                }
              }
            })
          )?.data?.createCustomerUserPasswordless?.id,
    [createCustomerUserMutation, customerId, updateCustomerUserMutation]
  );

  return [
    saveCustomer,
    createMutationOptions.loading || updateMutationOptions.loading
  ] as const;
};
