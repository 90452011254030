import { ArrowLeftOutlined } from '@ant-design/icons';
import { SelectControlled } from '@retail/backoffice-ui';
import { Button, Col, Form, Row } from 'antd';
import { useEffect } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputControlled } from '~/components/Form/InputControlled';
import { emailValidation } from '~/utils/validation/email';

import { PhoneInputControlled } from './PhoneInputControlled';
import { localeOptionsByCountries } from './countryLocaleOptions';

export type CustomerFormVM = {
  email: string;
  locale: string;
  phone: string;
  firstName: string;
  lastName: string;
};

type CustomerFormProps = {
  onSubmit: (values: CustomerFormVM) => void;
  onBack: () => void;
  loading: boolean;
  isNewCustomer: boolean;
  defaultValues: DefaultValues<CustomerFormVM>;
  country: string;
};

export const CustomerForm = ({
  defaultValues,
  onSubmit,
  loading,
  isNewCustomer,
  onBack,
  country
}: CustomerFormProps) => {
  const { control, handleSubmit, reset } = useForm<CustomerFormVM>({
    defaultValues
  });
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const { t } = useTranslation();
  const countryLocaleOptions = (localeOptionsByCountries[country] ?? []).map(
    (option) => ({
      ...option,
      label: t(option.label)
    })
  );

  return (
    <Form
      onFinish={handleSubmit(onSubmit)}
      data-qa-selector="AHUserForm"
      noValidate
      layout="vertical"
    >
      <Row gutter={32}>
        <Col sm={12}>
          <InputControlled
            required
            type="email"
            qaSelector="email"
            size="large"
            label={t('bo.orderCreate.email')}
            disabled={!isNewCustomer}
            controllerProps={{
              name: 'email',
              control,
              rules: {
                required: true,
                validate: emailValidation(t)
              }
            }}
          />
        </Col>
        <Col sm={12}>
          <PhoneInputControlled
            required
            qaSelector="phone"
            type="phone"
            size="large"
            label={t('bo.orderCreate.phone')}
            controllerProps={{
              name: 'phone',
              control,
              rules: {
                required: true
              }
            }}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col sm={12}>
          <InputControlled
            required
            qaSelector="firstName"
            size="large"
            label={t('bo.orderCreate.firstName')}
            controllerProps={{
              name: 'firstName',
              control,
              rules: {
                required: true
              }
            }}
          />
        </Col>
        <Col sm={12}>
          <InputControlled
            required
            qaSelector="lastName"
            size="large"
            label={t('bo.orderCreate.lastName')}
            controllerProps={{
              name: 'lastName',
              control,
              rules: {
                required: true
              }
            }}
          />
        </Col>
      </Row>
      {countryLocaleOptions.length > 1 && (
        <Row gutter={32}>
          <Col sm={12}>
            <SelectControlled
              label={t('bo.orderCreate.userLanguage')}
              required
              size="large"
              options={countryLocaleOptions}
              controllerProps={{
                name: 'locale',
                control,
                rules: {
                  required: true
                }
              }}
            />
          </Col>
        </Row>
      )}

      <Row style={{ flex: 1 }} gutter={16}>
        <Col>
          <Button size="large" onClick={onBack}>
            <ArrowLeftOutlined /> {t('bo.orderCreate.backToAutoHeroUserSearch')}
          </Button>
        </Col>
        <Col flex="1" />
        <Col>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            loading={loading}
          >
            {isNewCustomer
              ? t('bo.orderCreate.createUser')
              : t('bo.orderCreate.updateUser')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
