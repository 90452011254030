import { Dispatch } from 'react';

import { Arrows } from '../Arrows';
import { ImageViewer } from '../ImageViewer';
import { ObjectViewer } from '../ObjectViewer';
import { DocumentModel } from '../types';
import { ReturnGetFileMeta, isImageMimeType } from '../utils';

import cn from './styles.less';

interface Props {
  currentDocUrl: string;
  currentDocName: string;
  currentDocMeta?: ReturnGetFileMeta;
  documents?: DocumentModel[];
  isLoadingRotation?: boolean;
  onArrow: Dispatch<number>;
}

export function ViewerCard({
  currentDocUrl,
  currentDocName,
  currentDocMeta,
  documents,
  onArrow
}: Props) {
  return (
    <div className={cn.section}>
      {currentDocMeta?.mimeType && isImageMimeType(currentDocMeta.mimeType) ? (
        <ImageViewer
          key={currentDocUrl}
          fullUrl={currentDocUrl}
          fileName={currentDocName}
        />
      ) : (
        <ObjectViewer
          fullUrl={currentDocUrl}
          fileExtension={currentDocMeta?.fileExtension}
          fileName={currentDocName}
          mimeType={currentDocMeta?.mimeType}
        />
      )}
      {documents?.length && <Arrows onClick={onArrow} />}
    </div>
  );
}
