interface Props {
  flowVersion: string;
  orderNumber: string;
}

export function OrderNumberLabel({ flowVersion, orderNumber }: Props) {
  return (
    <>
      {orderNumber} <strong>({flowVersion})</strong>
    </>
  );
}
