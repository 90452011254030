import { TableCell, TableCellSpanProps } from '@retail/backoffice-ui';
import { useTranslation } from 'react-i18next';

interface Props extends TableCellSpanProps {
  value: boolean;
}

export function YesNoCell({ value, ...props }: Props) {
  const { t } = useTranslation();

  return (
    <TableCell {...props}>
      {value ? t('bo.appointment.Yes') : t('bo.appointment.No')}
    </TableCell>
  );
}
