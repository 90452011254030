import { format, isValid } from 'date-fns';

import { DATE_FORMAT, DATE_TIME_FORMAT } from '~/constants/date';

import { createParseDate } from './parseDate';

export const parseDate = createParseDate();
export const isValidDate = (date: string) => parseDate(isValid(date));

export const customFormatDate = (dateFormat) => (rawDate) => {
  const date = parseDate(rawDate);
  return isValid(date) ? format(date, dateFormat) : '';
};

export const formatDate = customFormatDate(DATE_FORMAT);
export const formatDateTime = customFormatDate(DATE_TIME_FORMAT);

export function toDate(value: string) {
  const date = parseDate(value);

  return isValid(date) ? new Date(date) : value;
}
