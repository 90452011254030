export enum PERMISSION {
  QUERY_ORDER = 'query/order',
  QUERY_SEARCH_ORDERS = 'query/searchorders',
  CREATE_BO_ORDER_USING_AH_CHECKOUT = 'action/create-bo-order-using-ah-checkout',
  MUTATION_CREATE_ORDER = 'mutation/createorder',
  MUTATION_CREATE_ADDITIONAL_ORDER = 'mutation/createadditionalorder',
  MUTATION_CREATE_ADDITIONAL_FEES_COLLECTION_ORDER = 'mutation/createadditionalfeescollectionorder',
  MUTATION_CREATE_ADDITIONAL_ITEMS_PURCHASE_ORDER = 'mutation/createadditionalitemspurchaseorder',
  MUTATION_CREATE_OTHER_ORDER = 'mutation/createotherorder',
  ORDER_SALES_AGENT_ASSIGNEES = 'query/ordersalesagentassignees',
  CUSTOMER_LEAD_ASSIGNABLE = 'CustomerLead.assignable',
  UPSELL_WARRANTY = 'mutation/upsellwarranty',
  UPSELL_WARRANTY_FORCE = 'mutation/upsellwarranty_force',
  UPSELL_SECONDARY_WHEELS = 'mutation/upsellsecondarywheels',
  UPSELL_TRADE_IN = 'mutation/upselltradein'
}
