import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderState } from '~/constants/OrderState';

export function useOrderStateOptions() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.values(OrderState).map((value) => ({
        value,
        label: t(`bo.taskManagement.orderStates.${value}`)
      })),
    [t]
  );
}
