import { SelectControlled } from '@retail/backoffice-ui';
import {
  OrderType,
  ORDER_TYPES,
  CONVERSION_TYPES
} from '@retail/order-constants';
import { Button, Col, Form, Row, Space, Tooltip } from 'antd';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { BrandButton } from '~/components/BrandButton';
import { useCheckPermissions } from '~/hooks/useCheckPermissions';
import { useLocalizedOptions } from '~/hooks/useLocalizedOptions';
import { CONVERSION_TYPE_OPTIONS } from '~/utils/options/order/conversionType';
import { ORDER_VERSION_OPTIONS } from '~/utils/options/order/orderVersion';

import { useCheckoutBranchOptions } from './useCheckoutBranchOptions';

interface FormValues {
  orderVersion: string;
  conversionType: string;
  checkoutBranchId: number;
}

interface CreationDetailsProps {
  orderType: OrderType;
  retailCountry: string | null;
  isCarInShowroomEnabled: boolean;
  isCreateBoOrderUsingAhCheckoutEnabled: boolean;
  isSubmitDisabled: boolean;
  isSubmitLoading: boolean;
  onSubmit: (values: FormValues) => void;
  handleCreateOrderFromAhCheckout: (values: FormValues) => void;
}

export const CreationDetails = ({
  orderType,
  retailCountry,
  isCarInShowroomEnabled,
  isCreateBoOrderUsingAhCheckoutEnabled,
  isSubmitDisabled,
  isSubmitLoading,
  onSubmit,
  handleCreateOrderFromAhCheckout
}: CreationDetailsProps) => {
  const { t } = useTranslation();

  const { branchOptions, isBranchOptionsLoading } = useCheckoutBranchOptions({
    retailCountry,
    isCarInShowroomEnabled
  });

  const orderVersionOptions = useLocalizedOptions(ORDER_VERSION_OPTIONS);

  const formInstance = useForm<FormValues>({
    defaultValues: {
      orderVersion:
        orderVersionOptions.length === 1
          ? orderVersionOptions[0].value
          : undefined
    }
  });

  const conversionTypeOptions = useLocalizedOptions(
    CONVERSION_TYPE_OPTIONS.filter(
      ({ value }) => value != CONVERSION_TYPES.ONLINE_CHECKOUT
    )
  );

  const { control } = formInstance;

  const conversionTypeValue = useWatch({
    name: 'conversionType',
    control
  });

  const isBranchRequired =
    conversionTypeValue === CONVERSION_TYPES.BRANCH_CHECKOUT;

  const { isAllowed: isCreateBoOrderUsingAhCheckoutAllowed } =
    useCheckPermissions({
      allow: PERMISSION.CREATE_BO_ORDER_USING_AH_CHECKOUT,
      domain: DOMAIN.ORDER_MANAGEMENT
    });

  return (
    <FormProvider {...formInstance}>
      <Form
        layout="vertical"
        onFinish={async () => {
          await formInstance.trigger();
          await formInstance.handleSubmit(onSubmit)();
        }}
      >
        <Row gutter={32}>
          <Col sm={12}>
            <SelectControlled
              label={t('bo.orderCreate.creationDetails.orderVersion.label')}
              required
              controllerProps={{
                control,
                name: 'orderVersion',
                rules: { required: true }
              }}
              options={orderVersionOptions}
              disabled={orderVersionOptions.length === 1}
            />
          </Col>
          <Col sm={12}>
            <SelectControlled
              required
              label={t('bo.orderCreate.creationDetails.conversionType.label')}
              placeholder={t(
                'bo.orderCreate.creationDetails.conversionType.placeholder'
              )}
              controllerProps={{
                control,
                name: 'conversionType',
                rules: { required: true }
              }}
              options={conversionTypeOptions}
            />
            {isCarInShowroomEnabled && (
              <SelectControlled
                required={isBranchRequired}
                label={t('bo.orderCreate.creationDetails.checkoutBranch.label')}
                placeholder={t(
                  'bo.orderCreate.creationDetails.checkoutBranch.placeholder'
                )}
                controllerProps={{
                  control,
                  name: 'checkoutBranchId',
                  rules: {
                    required: isBranchRequired
                  }
                }}
                options={branchOptions}
                loading={isBranchOptionsLoading}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col flex={1} />
          {isCreateBoOrderUsingAhCheckoutEnabled &&
          isCreateBoOrderUsingAhCheckoutAllowed &&
          orderType === ORDER_TYPES.STANDARD ? (
            <Space size="large">
              <Col>
                <Tooltip
                  title={t('bo.orderCreate.createFromAhCheckout.tooltip')}
                >
                  <BrandButton
                    size="large"
                    type="primary"
                    disabled={isSubmitDisabled}
                    loading={isSubmitLoading}
                    data-qa-selector="createOrderFromAhCheckoutButton"
                    onClick={async () => {
                      await formInstance.trigger();
                      await formInstance.handleSubmit(
                        handleCreateOrderFromAhCheckout
                      )();
                    }}
                  >
                    {t('bo.orderCreate.createFromAhCheckout')}
                  </BrandButton>
                </Tooltip>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  size="large"
                  type="primary"
                  disabled={isSubmitDisabled}
                  loading={isSubmitLoading}
                  data-qa-selector="createOrderFromBoButton"
                >
                  {t('bo.orderCreate.createFromBo')}
                </Button>
              </Col>
            </Space>
          ) : (
            <Col>
              <Button
                htmlType="submit"
                size="large"
                type="primary"
                disabled={isSubmitDisabled}
                loading={isSubmitLoading}
                data-qa-selector="createOrderButton"
              >
                {t('bo.orderCreate.create')}
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </FormProvider>
  );
};
