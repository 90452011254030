import { OptionModel } from '@retail/backoffice-ui';
import { Col, Form, Row, Select } from 'antd';
import { FilterFunc } from 'rc-select/lib/Select';
import { useTranslation } from 'react-i18next';

import { useAccessibleCountryOptions } from '~/hooks/useAccessibleCountryOptions';

export const filterOption: FilterFunc<OptionModel> = (input, option) =>
  (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase());

type SelectRetailCountryFormProps = {
  onChange: (val: string) => void;
  country: string;
};

export const SelectRetailCountryForm = ({
  onChange,
  country
}: SelectRetailCountryFormProps) => {
  const { t } = useTranslation();
  const countryOptions = useAccessibleCountryOptions();

  return (
    <Row>
      <Col sm={6}>
        <Form layout="vertical">
          <Form.Item label={t('bo.orderCreate.retailCountry')} required>
            <Select
              showSearch
              filterOption={filterOption}
              size="large"
              value={country}
              onChange={onChange}
              options={countryOptions}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
