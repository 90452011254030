import { TableCell, formatCurrency } from '@retail/backoffice-ui';
import { Table, TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RetailWarrantyProjection } from '~/apollo/gql-types';

export function useColumns() {
  const { t } = useTranslation();

  return useMemo(
    (): TableColumnsType<RetailWarrantyProjection> => [
      {
        dataIndex: 'internalReferenceName',
        title: (
          <strong>
            {t(
              'bo.summaryVerificationWarrantyUpsell.table.column.internalReferenceName'
            )}
          </strong>
        ),
        render: (value) => (
          <TableCell data-qa-selector="internalReferenceName">
            {value}
          </TableCell>
        )
      },
      {
        dataIndex: ['price', 'grossPriceMinorUnits'],
        title: (
          <strong>
            {t('bo.summaryVerificationWarrantyUpsell.table.column.price')}
          </strong>
        ),
        render: (amountMinorUnits, { currencyCode }) => (
          <TableCell data-qa-selector="price">
            {formatCurrency({
              amountMinorUnits,
              currencyCode
            })}
          </TableCell>
        )
      },
      Table.SELECTION_COLUMN
    ],
    [t]
  );
}
