import {
  Layout,
  LayoutBody,
  LayoutHeader,
  PaginatedTable
} from '@retail/backoffice-ui';
import { Space } from 'antd';
import { BaseSyntheticEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { withPermissions } from '~/components/PermissionChecker';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

import { ColumnSettingButton } from './ColumnSettingButton';
import { CreateOrderButton } from './CreateOrderButton';
import { SearchForm } from './SearchForm';
import { useColumns } from './hooks/useColumns';
import { useColumnsConfig } from './hooks/useColumnsConfig';
import { DEFAULT_VALUES, useDataSource } from './hooks/useDataSource';
import cn from './styles.less';
import { OrderOverviewSearchModel } from './types';

export const OrderOverview = withPermissions(
  PERMISSION.QUERY_SEARCH_ORDERS,
  DOMAIN.ORDER_MANAGEMENT
)(() => {
  const { t } = useTranslation();
  const {
    controller,
    dataSource,
    isLoading,
    formValues,
    refetch,
    onPageChange,
    updateController,
    clearController,
    onChange
  } = useDataSource();
  const form = useForm<OrderOverviewSearchModel>({
    defaultValues: formValues
  });
  const [columnConfig, setColumnConfig] = useColumnsConfig();
  const columns = useColumns({ controller, columnConfig });
  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
    getValues,
    setValue
  } = form;

  useEffect(() => {
    reset(formValues, { keepDirty: false });
  }, [formValues, reset]);

  function onSubmit(e: BaseSyntheticEvent) {
    if (isDirty) {
      handleSubmit(updateController)(e);
    } else {
      refetch();
    }
  }

  function onReset() {
    clearController();
    refetch();
    reset(DEFAULT_VALUES);
  }

  function handleRefetch() {
    Promise.resolve().then(() => {
      updateController(getValues());
      refetch();
    });
  }

  return (
    <Layout>
      <LayoutHeader title={t('bo.orderOverview.title')}>
        <div className={cn.headerButtons}>
          <ColumnSettingButton
            columnConfig={columnConfig}
            setColumnConfig={setColumnConfig}
          />
          <CreateOrderButton />
        </div>
      </LayoutHeader>
      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <FormProvider {...form}>
            <SearchForm
              isLoading={isLoading}
              control={control}
              onSubmit={onSubmit}
              onReset={onReset}
              refetch={handleRefetch}
              setValue={setValue}
              disabled={!isValid}
            />
          </FormProvider>
          <PaginatedTable
            dataSource={dataSource}
            columns={columns}
            isLoading={isLoading}
            controller={controller}
            onChange={onChange}
            onPageChange={onPageChange}
          />
        </Space>
      </LayoutBody>
    </Layout>
  );
});
