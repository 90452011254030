import { TableCell, formatCurrency } from '@retail/backoffice-ui';
import { Table, TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { YesNoCell } from '~/pages/OrderOverview/YesNoCell';
import { formatDateTime } from '~/utils/date';

import { RetailTradeInUpSellOptionModel } from './types';

export function useColumns() {
  const { t } = useTranslation();

  return useMemo(
    (): TableColumnsType<RetailTradeInUpSellOptionModel> => [
      {
        dataIndex: 'stockNumber',
        title: (
          <strong>
            {t('bo.summaryVerificationTradeInUpsell.table.column.stockNumber')}
          </strong>
        ),
        render: (value, { model, make, previewUrl }) => (
          <TableCell data-qa-selector="stockNumber">
            <Link to={previewUrl}>
              {[value, model, make].filter(Boolean).join(' ')}
            </Link>
          </TableCell>
        )
      },
      {
        dataIndex: 'grossPriceMinorUnits',
        title: (
          <strong>
            {t('bo.summaryVerificationTradeInUpsell.table.column.price')}
          </strong>
        ),
        render: (amountMinorUnits, { currencyCode }) => (
          <TableCell data-qa-selector="price">
            {formatCurrency({
              amountMinorUnits,
              currencyCode
            })}
          </TableCell>
        )
      },
      {
        dataIndex: 'state',
        title: (
          <strong>
            {t('bo.summaryVerificationTradeInUpsell.table.column.state')}
          </strong>
        ),
        render: (value) => (
          <TableCell data-qa-selector="state">
            {value
              ? t(
                  `bo.summaryVerificationTradeInUpsell.table.column.state.${value}`
                )
              : null}
          </TableCell>
        )
      },
      {
        dataIndex: 'validTill',
        title: (
          <strong>
            {t('bo.summaryVerificationTradeInUpsell.table.column.validTill')}
          </strong>
        ),
        render: (value) => (
          <TableCell data-qa-selector="validTill">
            {formatDateTime(value)}
          </TableCell>
        )
      },
      {
        dataIndex: 'isFinanced',
        title: (
          <strong>
            {t('bo.summaryVerificationTradeInUpsell.table.column.isFinanced')}
          </strong>
        ),
        render: (value) => (
          <YesNoCell value={value} data-qa-selector="isFinanced" />
        )
      },
      {
        dataIndex: 'outstandingFinancingDueAmountMinorUnits',
        title: (
          <strong>
            {t(
              'bo.summaryVerificationTradeInUpsell.table.column.outstandingFinancingDueAmountMinorUnits'
            )}
          </strong>
        ),
        render: (value) => (
          <TableCell data-qa-selector="outstandingFinancingDueAmountMinorUnits">
            {Number.isFinite(value) ? value / 100 : null}
          </TableCell>
        )
      },
      Table.SELECTION_COLUMN
    ],
    [t]
  );
}
