import * as PaymentType from '@retail/order-constants/src/paymentTypes/enum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function usePaymentTypeOptions() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.values(PaymentType).map((value) => ({
        value,
        label: t(`bo.taskManagement.paymentTypes.${value}`)
      })),
    [t]
  );
}
