import { ORDER_TYPES } from '@retail/order-constants/src/types';

import { OrderCreateFlowParams } from '~/constants/searchParams';

import { isAdditionalOrderCreateReason } from './isAdditionalOrderCreateReason';

export const isSearchParamsValid = (searchParams: URLSearchParams) => {
  const orderType = searchParams.get(OrderCreateFlowParams.OrderType);
  const orderCreateReason = searchParams.get(OrderCreateFlowParams.Reason);

  const hasParams = (...names: string[]) =>
    names.every((name) => Boolean(searchParams.get(name)));

  switch (orderType) {
    case ORDER_TYPES.STANDARD:
      return hasParams(OrderCreateFlowParams.StockNumber);
    case ORDER_TYPES.ADDITIONAL:
      return (
        hasParams(OrderCreateFlowParams.LinkedOrderId) &&
        isAdditionalOrderCreateReason(orderCreateReason)
      );
    case ORDER_TYPES.OTHER:
      return true;
    default:
      return false;
  }
};
