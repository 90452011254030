import { BROWSER_MAP, getParser as getBrowserParser } from 'bowser';
import { useCallback, useEffect, useState } from 'react';

import { Loader } from '../Loader';

import cn from './styles.less';

const isChrome = getBrowserParser(window.navigator.userAgent).isBrowser(
  BROWSER_MAP.chrome
);

interface Props {
  fullUrl: string;
  fileName: string;
  fileExtension: string;
  mimeType: string;
}

export function ObjectViewer({
  fullUrl,
  fileExtension,
  fileName,
  mimeType
}: Props) {
  const [objUrl, setObjUrl] = useState<string | null>(null);

  const requiresObjUrlForPdf = isChrome && fileExtension === 'pdf';
  const dataUrl = requiresObjUrlForPdf ? objUrl : fullUrl;

  useEffect(() => {
    let revoke: VoidFunction;

    // Requires a hack to preview PDFs in Chrome as the BE doesn't send
    // the "Content-Type: application/pdf" header and it makes Chrome immediately
    // download a file
    fetch(fullUrl)
      .then((res) => res.blob())
      .then((blob) => blob.slice(0, blob.size, 'application/pdf'))
      .then((data) => {
        const objectUrl = URL.createObjectURL(data);

        setObjUrl(objectUrl);

        revoke = () => URL.revokeObjectURL(objectUrl);
      });

    return () => revoke?.();
  }, [fullUrl]);

  const [isObjectLoaded, setObjectLoaded] = useState(false);
  const objectRefCallback = useCallback((elem: HTMLObjectElement | null) => {
    if (elem) {
      setObjectLoaded(false);
    }
  }, []);

  const showLoader = requiresObjUrlForPdf
    ? !objUrl || !isObjectLoaded
    : !isObjectLoaded;

  return (
    <>
      <object
        key={dataUrl}
        ref={objectRefCallback}
        data-qa-selector="objectViewer"
        data={dataUrl ?? undefined}
        type={mimeType ?? undefined}
        className={cn.section}
        onLoad={() => setObjectLoaded(true)}
      >
        <a href={fullUrl} target="_blank" rel="noreferrer noopener">
          {fileName}
        </a>
      </object>
      {showLoader && <Loader />}
    </>
  );
}
