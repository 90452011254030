import { EditOutlined } from '@ant-design/icons';
import { LinkButton, useHref } from '@retail/backoffice-urls';

import { PermissionChecker } from '~/components/PermissionChecker';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

import cn from './styles.less';

interface Props {
  orderId: string;
}

export function Actions({ orderId }: Props) {
  const href = useHref('ORDER', { orderId });

  return (
    <div data-qa-selector="actions" className={cn.actions}>
      <PermissionChecker
        allow={PERMISSION.QUERY_ORDER}
        domain={DOMAIN.ORDER_MANAGEMENT}
      >
        <LinkButton
          to={href}
          type="primary"
          size="small"
          icon={<EditOutlined />}
        />
      </PermissionChecker>
    </div>
  );
}
