import { ClearOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tooltip } from 'antd';
import {
  Dispatch,
  FunctionComponent,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { SortEnd, SortableContainerProps } from 'react-sortable-hoc';

import {
  DEFAULT_ORDER_OVERVIEW_COLUMN_CONFIG,
  ORDER_OVERVIEW_COLUMN_NAME_TO_TRANSLATION_KEY
} from '../constants';
import { ColumnConfig } from '../types';

import { SortableBody } from './SortableBody';
import { SortableItem } from './SortableItem';
import cn from './styles.less';
import { useColumns } from './useColumns';

interface Props {
  columnConfig: ColumnConfig[];
  setColumnConfig: Dispatch<ColumnConfig[]>;
}

export function ColumnSettingButton({ columnConfig, setColumnConfig }: Props) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onVisibleChange = useCallback(
    (name: string, value: boolean) =>
      setColumnConfig(
        columnConfig.map((x) => ({
          ...x,
          isVisible: x.name === name ? value : x.isVisible
        }))
      ),
    [columnConfig, setColumnConfig]
  );

  const columns = useColumns(onVisibleChange);

  function showModal() {
    setIsModalOpen(true);
  }

  function onCancel() {
    setIsModalOpen(false);
  }

  const dataSource = useMemo(
    () =>
      columnConfig.map((x) => ({
        ...x,
        title: t(ORDER_OVERVIEW_COLUMN_NAME_TO_TRANSLATION_KEY[x.name])
      })),
    [columnConfig, t]
  );

  function onSortEnd({ oldIndex, newIndex }: SortEnd) {
    if (oldIndex !== newIndex) {
      const nextColumnConfig = columnConfig.slice();

      const [deletedItem] = nextColumnConfig.splice(oldIndex, 1);
      nextColumnConfig.splice(newIndex, 0, deletedItem);

      setColumnConfig(nextColumnConfig);
    }
  }

  function DraggableContainer(props: SortableContainerProps) {
    return (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass={cn.rowDragging}
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  }

  function DraggableBodyRow(props: FunctionComponent) {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.name === props['data-row-key']);

    return <SortableItem index={index} {...props} />;
  }

  function onReset() {
    setColumnConfig(DEFAULT_ORDER_OVERVIEW_COLUMN_CONFIG);
  }

  return (
    <>
      <Tooltip title={t('bo.orderOverview.columnSetting.title')}>
        <Button size="large" icon={<SettingOutlined />} onClick={showModal} />
      </Tooltip>
      <Modal
        title={t('bo.orderOverview.columnSetting.title')}
        visible={isModalOpen}
        footer={
          <div>
            <Button onClick={onReset} icon={<ClearOutlined />} />
          </div>
        }
        onCancel={onCancel}
      >
        <Table
          size="small"
          rowKey="name"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </Modal>
    </>
  );
}
