import { CopyOutlined } from '@ant-design/icons';
import { useHref } from '@retail/backoffice-urls';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

import { PermissionChecker } from '../PermissionChecker';

import { OrderNumberLabel } from './OrderNumberLabel';
import cn from './styles.less';

const { Text } = Typography;

interface Props {
  flowVersion: string;
  orderNumber: string;
  orderId: string;
}

export function OrderNumber({ flowVersion, orderNumber, orderId }: Props) {
  const href = useHref('ORDER', { orderId });
  const label = (
    <OrderNumberLabel flowVersion={flowVersion} orderNumber={orderNumber} />
  );

  return (
    <PermissionChecker
      allow={PERMISSION.QUERY_ORDER}
      domain={DOMAIN.ORDER_MANAGEMENT}
      fallback={label}
    >
      <Text
        className={cn.text}
        copyable={{
          text: orderNumber,
          icon: <CopyOutlined data-qa-selector="copyOrderNumberIcon" />
        }}
      >
        <Link to={href}>{label}</Link>
      </Text>
    </PermissionChecker>
  );
}
