// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KQ0DvsxvdD9odZZWDKjT {\n  width: 100%;\n}\n.dOer7za8B0WuEMsmZd2C {\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OrderOverview/styles.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAEA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;AAAF","sourcesContent":[".fullWidth {\n  width: 100%;\n}\n\n.headerButtons {\n  display: flex;\n  justify-content: center;\n  gap: 16px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "KQ0DvsxvdD9odZZWDKjT",
	"headerButtons": "dOer7za8B0WuEMsmZd2C"
};
export default ___CSS_LOADER_EXPORT___;
