import { Input } from 'antd';
import { useController, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormItem } from '@retail/backoffice-ui';

import { FormValues } from './types';

interface StockNumberSearchProps {
  form: UseFormReturn<FormValues>;
  isLoading: boolean;
  onSearch: (
    value: string
  ) => Promise<{ id: string; isReserved: boolean } | undefined>;
}

export const StockNumberSearch = ({
  form,
  isLoading,
  onSearch
}: StockNumberSearchProps) => {
  const { t } = useTranslation();

  const handleSearch = async (value: string) => {
    const stockNumber = value.trim();
    if (stockNumber) {
      form.clearErrors('stockNumber');
      const vehicle = await onSearch(stockNumber);
      const isVehicleExists = Boolean(vehicle?.id);
      if (!isVehicleExists) {
        form.setError('stockNumber', {
          message: t(
            'bo.orderOverview.orderTypeForm.stockNumber.errorNoVehicle'
          )
        });
      }
      if (vehicle?.isReserved) {
        form.setError('stockNumber', {
          message: t(
            'bo.orderOverview.orderTypeForm.stockNumber.errorAdIsReserved',
            // used default value to unblock release because translation from phrase is not displayed
            { defaultValue: 'This car is already reserved' }
          )
        });
      }
    } else {
      form.trigger('stockNumber');
    }
  };

  const controllerProps = {
    control: form.control,
    name: 'stockNumber' as const,
    rules: { required: true }
  };

  const { field } = useController(controllerProps);

  return (
    <FormItem
      required
      label={t('bo.orderOverview.orderTypeForm.stockNumber.label')}
      controllerProps={controllerProps}
    >
      <Input.Search
        {...field}
        data-qa-selector="stockNumberSearch"
        enterButton={
          <span data-qa-selector="stockNumberSearchButton">
            {t('bo.orderOverview.orderTypeForm.stockNumberSearch')}
          </span>
        }
        onSearch={handleSearch}
        loading={isLoading}
      />
    </FormItem>
  );
};
