import { CloseOutlined } from '@ant-design/icons';
import { FullScreenModal } from '@retail/backoffice-ui/src/FullScreenModal';
import { Button } from 'antd';
import {
  Fragment,
  PropsWithChildren,
  ReactElement,
  cloneElement,
  isValidElement,
  useLayoutEffect,
  useState
} from 'react';

import { DownloadButton } from './DownloadButton';
import { ViewerCard } from './ViewerCard';
import cn from './styles.less';
import { DocumentModel } from './types';
import { getFileMeta } from './utils';

export interface DocumentViewerProps {
  documents: DocumentModel[];
  initialId?: string;
}

export function DocumentViewer({
  documents,
  initialId,
  children
}: PropsWithChildren<DocumentViewerProps>) {
  const [currentId, setCurrentId] = useState(initialId);
  const [isOpenModal, setOpenModal] = useState(false);

  useLayoutEffect(() => {
    if (documents[0]) {
      setCurrentId(documents[0].id);
    }
  }, [documents]);

  const currentDoc = documents.find((it) => it.id === currentId);
  const currentDocUrl = currentDoc?.fullUrl;
  const currentDocMeta = getFileMeta({
    fullUrl: currentDocUrl,
    fileName: currentDoc?.fileName
  });

  function onArrow(diff: number) {
    const calculateNextIdx = (currentId?: string) => {
      const currentIdx = documents.findIndex((it) => it.id === currentId);
      const nextIdx = currentIdx + diff;
      const maxIdx = documents.length - 1;
      const newIdx = nextIdx < 0 ? maxIdx : nextIdx > maxIdx ? 0 : nextIdx;

      return documents[newIdx].id;
    };

    setCurrentId(calculateNextIdx);
  }

  const currentDocName = currentDocMeta.fileName || 'Unknown';

  const openModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  return (
    <Fragment>
      <FullScreenModal
        visible={isOpenModal}
        zIndex={1040}
        title={
          <div className={cn.toolbar}>
            <div data-qa-selector="docName" className={cn.documentName}>
              <span>{currentDocName}</span>
            </div>
            <div className={cn.toolbarButtons}>
              <DownloadButton
                currentDocUrl={currentDocUrl}
                fileName={currentDocMeta?.fileName}
              />
              <Button
                data-qa-selector="closeButton"
                size="large"
                icon={<CloseOutlined />}
                onClick={closeModal}
              />
            </div>
          </div>
        }
        closable={false}
        onCancel={closeModal}
        footer={null}
      >
        {currentDocUrl && (
          <ViewerCard
            currentDocUrl={currentDocUrl}
            currentDocName={currentDocName}
            currentDocMeta={currentDocMeta}
            documents={documents}
            onArrow={onArrow}
          />
        )}
      </FullScreenModal>
      {isValidElement(children)
        ? cloneElement(children as ReactElement, {
            onClick: openModal
          })
        : null}
    </Fragment>
  );
}
