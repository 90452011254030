import {
  createBaseFilter,
  createFilter,
  createFilterPayload,
  createRangeFilter,
  createSort,
  Filter,
  FilterType,
  Sort,
  Sorts
} from '@retail/gql-utils';
import { endOfDay, startOfDay } from 'date-fns';

import { WarrantyLevelType } from '~/apollo/gql-types';
import { PAGE_SIZE } from '~/constants/common';

import { OrderOverviewColumnName } from '../constants';
import { OrderOverviewSearchModel } from '../types';

const DEFAULT_SORTING = Sorts.desk('createdAt');

function formatExitCheckState(state: boolean) {
  if (state)
    return {
      op: FilterType.EQUAL,
      value: 'COMPLETED'
    };

  return {
    op: FilterType.NOT,
    value: [
      createFilter(
        OrderOverviewColumnName.ExitCheckCompleted,
        FilterType.EQUAL,
        'COMPLETED'
      )
    ]
  };
}

function createDateRangeFilter(field: string, value: string[]) {
  if (value?.filter(Boolean).length === 2) {
    const [startDate, endDate] = value.map((x) => new Date(x));

    return createRangeFilter(
      field,
      JSON.stringify([
        [FilterType.GREATER_OR_EQUAL, startOfDay(startDate)],
        [FilterType.LESS_OR_EQUAL, endOfDay(endDate)]
      ])
    );
  }

  return undefined;
}

function createWarrantyFilter(warranty: string | number) {
  const filters: Filter[] = [];

  if (warranty) {
    if (warranty === WarrantyLevelType.Basic) {
      filters.push(
        createFilter(
          'warranty.level',
          FilterType.EQUAL,
          WarrantyLevelType.Basic
        )
      );
    } else if (Number.isFinite(warranty)) {
      filters.push(
        createFilter(
          'warranty.level',
          FilterType.EQUAL,
          WarrantyLevelType.Premium
        )
      );
      filters.push(
        createFilter('warranty.monthsCovered', FilterType.EQUAL, warranty)
      );
    }
  }

  return filters;
}

export function formToInput({
  page: rawPage,
  state,
  retailCountry,
  stockNumber,
  orderNumber,
  customerEmail,
  customerLastName,
  paymentStatus,
  paymentType,
  financeFlowType,
  financingType,
  financeApplicationStatus,
  handoverDateSet,
  deliveryOption,
  registrationDocumentsReceived,
  carInBranch,
  documentRequests,
  tradeIn,
  branchCheckupSuccessful,
  licensePlate,
  exitCheckState,
  retailDeliveryETAAvailable,
  carRetailReady,
  sort,
  direction,
  overdueBranchDelivery,
  overdueMaxEta,
  flowVersion,
  salesAgentAssignedTo,
  createdAt,
  contractSignedOn,
  pendingVerificationOn,
  completedOn,
  deliveredOn,
  verifiedOn,
  canceledOn,
  opportunityId,
  cancellationRequested,
  type,
  conversionType,
  customerType,
  warranty,
  daysInInventory,
  secondaryWheelsAdded
}: OrderOverviewSearchModel) {
  const page = Number(rawPage) - 1 || 0;
  const dirtyFilters = [];

  if (overdueBranchDelivery) {
    dirtyFilters.push(
      createRangeFilter(
        OrderOverviewColumnName.OverdueBranchDelivery,
        overdueBranchDelivery
      )
    );
  }

  if (overdueMaxEta) {
    dirtyFilters.push(
      createRangeFilter(OrderOverviewColumnName.OverdueMaxEta, overdueMaxEta)
    );
  }

  if (retailCountry?.length) {
    dirtyFilters.push(
      createFilter('retailCountry', FilterType.IN, retailCountry)
    );
  }

  if (flowVersion) {
    dirtyFilters.push(
      createFilter('flowVersion', FilterType.EQUAL, flowVersion)
    );
  }

  if (opportunityId) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.OpportunityId,
        FilterType.EQUAL,
        opportunityId
      )
    );
  }

  if (state?.length) {
    dirtyFilters.push(
      createFilter(OrderOverviewColumnName.State, FilterType.IN, state)
    );
  }

  if (stockNumber) {
    const value = stockNumber.toString().trim().toUpperCase();

    dirtyFilters.push(
      createFilter(OrderOverviewColumnName.StockNumber, FilterType.OR, [
        createFilter(
          OrderOverviewColumnName.StockNumber,
          FilterType.EQUAL,
          value
        ),
        createFilter(OrderOverviewColumnName.VIN, FilterType.EQUAL, value)
      ])
    );
  }

  if (orderNumber) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.OrderNumber,
        FilterType.EQUAL,
        orderNumber.toString().trim()
      )
    );
  }

  if (customerEmail) {
    dirtyFilters.push(
      createBaseFilter(
        'customer.email',
        FilterType.LIKE,
        customerEmail.toString().trim()
      )
    );
  }

  if (registrationDocumentsReceived != null) {
    dirtyFilters.push(
      createFilter(
        'registrationDocumentsReceived',
        FilterType.EQUAL,
        registrationDocumentsReceived
      )
    );
  }

  if (exitCheckState != null) {
    const formattedState = formatExitCheckState(exitCheckState);

    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.ExitCheckCompleted,
        formattedState.op,
        formattedState.value
      )
    );
  }

  if (retailDeliveryETAAvailable != null) {
    dirtyFilters.push(
      createFilter(
        'retailDeliveryETAAvailable',
        FilterType.EQUAL,
        retailDeliveryETAAvailable
      )
    );
  }

  if (carRetailReady != null) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.CarRetailReady,
        FilterType.EQUAL,
        carRetailReady
      )
    );
  }

  if (branchCheckupSuccessful != null) {
    dirtyFilters.push(
      createFilter(
        'branchCheckupSuccessful',
        FilterType.EQUAL,
        branchCheckupSuccessful
      )
    );
  }

  if (carInBranch != null) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.CarInBranch,
        FilterType.EQUAL,
        carInBranch
      )
    );
  }

  if (documentRequests != null) {
    const DOCUMENTS_ZERO_QUANTITY = 0;

    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.DocumentRequests,
        documentRequests ? FilterType.GREATER_THAN : FilterType.EQUAL,
        DOCUMENTS_ZERO_QUANTITY
      )
    );
  }

  if (tradeIn != null) {
    dirtyFilters.push(
      createFilter(OrderOverviewColumnName.TradeIn, FilterType.EQUAL, tradeIn)
    );
  }

  if (cancellationRequested) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.CancellationRequested,
        FilterType.EQUAL,
        cancellationRequested
      )
    );
  }

  if (handoverDateSet != null) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.HandoverAppointmentSet,
        FilterType.EQUAL,
        handoverDateSet
      )
    );
  }

  if (deliveryOption?.length) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.DeliveryOption,
        FilterType.IN,
        deliveryOption
      )
    );
  }

  if (customerLastName) {
    dirtyFilters.push(
      createBaseFilter(
        'customer.lastName',
        FilterType.LIKE,
        customerLastName.toString().trim()
      )
    );
  }

  if (paymentStatus?.length) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.PaymentStatus,
        FilterType.IN,
        paymentStatus
      )
    );
  }

  if (paymentType?.length) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.PaymentType,
        FilterType.IN,
        paymentType
      )
    );
  }

  if (financeFlowType?.length) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.FinanceFlowType,
        FilterType.IN,
        financeFlowType
      )
    );
  }

  if (financingType?.length) {
    dirtyFilters.push(
      createFilter('financing.financingType', FilterType.IN, financingType)
    );
  }

  if (financeApplicationStatus?.length) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.FinancingStatus,
        FilterType.IN,
        financeApplicationStatus
      )
    );
  }

  if (licensePlate) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.LicensePlate,
        FilterType.EQUAL,
        licensePlate.toString().trim()
      )
    );
  }

  if (salesAgentAssignedTo?.length) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.SalesAgentAssignedTo,
        FilterType.IN,
        salesAgentAssignedTo
      )
    );
  }

  if (type?.length) {
    dirtyFilters.push(
      createFilter(OrderOverviewColumnName.Type, FilterType.IN, type)
    );
  }

  if (conversionType?.length) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.ConversionType,
        FilterType.IN,
        conversionType
      )
    );
  }

  if (customerType?.length) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.CustomerType,
        FilterType.IN,
        customerType
      )
    );
  }

  if (warranty?.length) {
    const warrantyFilters: Filter[] = [];

    warranty.forEach((x) => {
      const warrantyFilter = createWarrantyFilter(x);

      warrantyFilters.push(
        warrantyFilter.length === 1
          ? warrantyFilter[0]
          : createFilter(null, FilterType.AND, warrantyFilter)
      );
    });

    dirtyFilters.push(
      warrantyFilters.length === 1
        ? warrantyFilters[0]
        : createFilter(null, FilterType.OR, warrantyFilters)
    );
  }

  if (daysInInventory.filter(Number.isFinite)?.length) {
    const [from, to] = daysInInventory;

    dirtyFilters.push(
      createRangeFilter(
        OrderOverviewColumnName.DaysInInventory,
        JSON.stringify(
          [
            from ? [FilterType.GREATER_OR_EQUAL, from] : null,
            to ? [FilterType.LESS_OR_EQUAL, to] : null
          ].filter(Boolean)
        )
      )
    );
  }

  if (secondaryWheelsAdded != null) {
    dirtyFilters.push(
      createFilter(
        OrderOverviewColumnName.SecondaryWheelsAdded,
        FilterType.EQUAL,
        secondaryWheelsAdded
      )
    );
  }

  dirtyFilters.push(
    ...[
      createDateRangeFilter(OrderOverviewColumnName.CreatedAt, createdAt),
      createDateRangeFilter(
        OrderOverviewColumnName.ContractSignedOn,
        contractSignedOn
      ),
      createDateRangeFilter(
        OrderOverviewColumnName.PendingVerificationOn,
        pendingVerificationOn
      ),
      createDateRangeFilter('completedOn', completedOn),
      createDateRangeFilter(OrderOverviewColumnName.DeliveredOn, deliveredOn),
      createDateRangeFilter(OrderOverviewColumnName.VerifiedOn, verifiedOn),
      createDateRangeFilter(OrderOverviewColumnName.CanceledOn, canceledOn)
    ].filter(Boolean)
  );

  let filter: Filter = null;
  const filters = dirtyFilters.filter(Boolean);

  if (filters.length) {
    filter =
      filters.length > 1
        ? createFilter(null, FilterType.AND, filters)
        : filters[0];
  }

  let sorts: Sort[];
  const sorting = Array.isArray(sort) ? sort?.join?.('.') : sort;

  if (sorting === OrderOverviewColumnName.CreatedAt) {
    sorts = direction ? [createSort(sorting, direction)] : [];
  } else if (sorting === OrderOverviewColumnName.Warranty) {
    sorts = [
      createSort('warranty.level', direction),
      createSort('warranty.monthsCovered', direction),
      DEFAULT_SORTING
    ];
  } else if (sorting && direction) {
    sorts = [createSort(sorting, direction), DEFAULT_SORTING];
  } else {
    sorts = [DEFAULT_SORTING];
  }

  return createFilterPayload({
    filter,
    sorts,
    page,
    pageSize: PAGE_SIZE
  });
}
