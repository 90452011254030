import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionModel } from '~/types/OptionModel';

export function usePassedOptions() {
  const { t } = useTranslation();

  return useMemo(
    (): OptionModel[] => [
      {
        value: true,
        label: t(
          'bo.orderOverview.searchForm.field.branchCheckupSuccessful.option.passed'
        )
      },
      {
        value: false,
        label: t(
          'bo.orderOverview.searchForm.field.branchCheckupSuccessful.option.failed'
        )
      }
    ],
    [t]
  );
}
