import { COUNTRY_LANGUAGES, LOCALES } from '@retail/i18n/constants';
import { formatLocale } from '@retail/i18n/utils';

import { ENABLED_LOCALES } from '~/constants/locales';

const options = [
  {
    value: LOCALES.EN,
    label: 'bo.orderCreate.english'
  },
  {
    value: LOCALES.DE,
    label: 'bo.orderCreate.german'
  },
  {
    value: LOCALES.DE_AT,
    label: 'bo.orderCreate.germanAustria'
  },
  {
    value: LOCALES.ES,
    label: 'bo.orderCreate.spanish'
  },
  {
    value: LOCALES.FR,
    label: 'bo.orderCreate.french'
  },
  {
    value: LOCALES.FR_BE,
    label: 'bo.orderCreate.frenchBelgium'
  },
  {
    value: LOCALES.IT,
    label: 'bo.orderCreate.italian'
  },
  {
    value: LOCALES.NL,
    label: 'bo.orderCreate.dutchNetherlands'
  },
  {
    value: LOCALES.NL_BE,
    label: 'bo.orderCreate.dutchBelgium'
  },
  {
    value: LOCALES.PL,
    label: 'bo.orderCreate.polish'
  },
  {
    value: LOCALES.SV_SE,
    label: 'bo.orderCreate.swedish'
  }
];

const enabledLocaleOptions = options.filter((option) =>
  ENABLED_LOCALES.includes(option.value)
);

const languagesByCountryCode = COUNTRY_LANGUAGES as Record<string, string[]>;

export const localeOptionsByCountries = Object.keys(
  languagesByCountryCode
).reduce((accumulator, country) => {
  accumulator[country] = languagesByCountryCode[country]
    .map((language) =>
      enabledLocaleOptions.find(
        (localeOption) => localeOption.value === formatLocale(language, country)
      )
    )
    .filter((language) => language !== undefined);
  return accumulator;
}, {} as Record<string, Array<{ label: string; value: string }>>);
