import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { SelectControlled } from '@retail/backoffice-ui';
import { Button, Card, Col, Form, Row } from 'antd';
import { BaseSyntheticEvent, Dispatch, PropsWithChildren } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePickerRangeControlled } from '~/components/Form/DatePickerRangeControlled';
import { InputControlled } from '~/components/Form/InputControlled';
import { FLOW_VERSION_OPTIONS } from '~/constants/FlowVersion';
import { DATE_FORMAT } from '~/constants/date';
import { useAccessibleCountryOptions } from '~/hooks/useAccessibleCountryOptions';

import { useConversionTypeOptions } from '../hooks/useConversionTypeOptions';
import { useCustomerTypeOptions } from '../hooks/useCustomerTypeOptions';
import { useDeliveryOptions } from '../hooks/useDeliveryOptions';
import { useFinanceApplicationStatusOptions } from '../hooks/useFinanceApplicationStatusOptions';
import { useFinanceFlowTypeOptions } from '../hooks/useFinanceFlowTypeOptions';
import { useFinancingTypeOptions } from '../hooks/useFinancingTypeOptions';
import { useOrderTypeOptions } from '../hooks/useOrderTypeOptions';
import { useOverdueBranchDeliveryOptions } from '../hooks/useOverdueBranchDeliveryOptions';
import { useOverdueMaxEtaOptions } from '../hooks/useOverdueMaxEtaOptions';
import { usePassedOptions } from '../hooks/usePassedOptions';
import { usePaymentStatusOptions } from '../hooks/usePaymentStatusOptions';
import { usePaymentTypeOptions } from '../hooks/usePaymentTypeOptions';
import { useWarrantyOptions } from '../hooks/useWarrantyOptions';
import { OrderOverviewSearchModel } from '../types';

import { DaysInInventory } from './DaysInInventory';
import { FieldCol } from './FieldCol';
import { OnlyShowActive } from './OnlyShowActive';
import { OnlyShowMy } from './OnlyShowMy';
import { OrderState } from './OrderState';
import { SalesAgentAssignedTo } from './SalesAgentAssignedTo';
import { SelectConfirmation } from './SelectConfirmation';
import cn from './style.less';
import { formatLicensePlateValue, formatTextValue } from './utils';

const GAP = 16;

interface Props {
  isLoading: boolean;
  disabled: boolean;
  control: Control<OrderOverviewSearchModel>;
  onSubmit: Dispatch<BaseSyntheticEvent>;
  onReset: VoidFunction;
  refetch: VoidFunction;
  setValue: UseFormSetValue<OrderOverviewSearchModel>;
}

export function SearchForm({
  isLoading,
  disabled,
  control,
  onSubmit,
  onReset,
  refetch,
  setValue
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const retailCountryOptions = useAccessibleCountryOptions();
  const paymentStatusOptions = usePaymentStatusOptions();
  const paymentTypeOptions = usePaymentTypeOptions();
  const financeFlowTypeOptions = useFinanceFlowTypeOptions();
  const financingTypeOptions = useFinancingTypeOptions();
  const financeApplicationStatusOptions = useFinanceApplicationStatusOptions();
  const passedOptions = usePassedOptions();
  const overdueBranchDeliveryOptions = useOverdueBranchDeliveryOptions();
  const overdueMaxEtaOptions = useOverdueMaxEtaOptions();
  const deliveryOptions = useDeliveryOptions();
  const orderTypeOptions = useOrderTypeOptions();
  const conversionTypeOptions = useConversionTypeOptions();
  const customerTypeOptions = useCustomerTypeOptions();
  const warrantyOptions = useWarrantyOptions();

  return (
    <Card title={t('bo.orderOverview.searchForm.title')}>
      <Form
        disabled={isLoading}
        onFinish={onSubmit}
        data-qa-selector="searchForm"
        className={cn.form}
      >
        <Row gutter={[GAP, GAP]}>
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="retailCountry"
              label={t('bo.orderOverview.searchForm.field.retailCountry')}
              options={retailCountryOptions}
              controllerProps={{
                name: 'retailCountry',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <InputControlled
              allowClear
              format={formatTextValue}
              qaSelector="orderNumber"
              label={t('bo.orderOverview.searchForm.field.orderNumber')}
              placeholder={t('bo.orderOverview.searchForm.field.orderNumber')}
              controllerProps={{
                name: 'orderNumber',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <InputControlled
              allowClear
              format={formatTextValue}
              qaSelector="stockNumber"
              label={t('bo.orderOverview.searchForm.field.stockNumber')}
              placeholder={t('bo.orderOverview.searchForm.field.stockNumber')}
              controllerProps={{
                name: 'stockNumber',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="type"
              label={t('bo.orderOverview.searchForm.field.type')}
              options={orderTypeOptions}
              controllerProps={{
                name: 'type',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="conversionType"
              label={t('bo.orderOverview.searchForm.field.conversionType')}
              options={conversionTypeOptions}
              controllerProps={{
                name: 'conversionType',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="customerType"
              label={t('bo.orderOverview.searchForm.field.customerType')}
              options={customerTypeOptions}
              controllerProps={{
                name: 'customerType',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <InputControlled
              allowClear
              format={formatTextValue}
              qaSelector="opportunityId"
              label={t('bo.orderOverview.searchForm.field.opportunityId')}
              placeholder={t('bo.orderOverview.searchForm.field.opportunityId')}
              controllerProps={{
                name: 'opportunityId',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <InputControlled
              allowClear
              format={formatLicensePlateValue}
              qaSelector="licensePlate"
              label={t('bo.orderOverview.searchForm.field.licensePlate')}
              placeholder={t('bo.orderOverview.searchForm.field.licensePlate')}
              controllerProps={{
                name: 'licensePlate',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <InputControlled
              allowClear
              qaSelector="customerEmail"
              label={t('bo.orderOverview.searchForm.field.customerEmail')}
              placeholder={t('bo.orderOverview.searchForm.field.customerEmail')}
              controllerProps={{
                name: 'customerEmail',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <InputControlled
              allowClear
              format={formatTextValue}
              qaSelector="customerLastName"
              label={t('bo.orderOverview.searchForm.field.customerLastName')}
              placeholder={t(
                'bo.orderOverview.searchForm.field.customerLastName'
              )}
              controllerProps={{
                name: 'customerLastName',
                control
              }}
            />
          </FieldCol>
          <OrderState control={control} />
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="paymentStatus"
              label={t('bo.orderOverview.searchForm.field.paymentStatus')}
              options={paymentStatusOptions}
              controllerProps={{
                name: 'paymentStatus',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="paymentType"
              label={t('bo.orderOverview.searchForm.field.paymentType')}
              options={paymentTypeOptions}
              controllerProps={{
                name: 'paymentType',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              allowClear
              qaSelector="financingType"
              label={t('bo.orderOverview.searchForm.field.financingType')}
              options={financingTypeOptions}
              controllerProps={{
                name: 'financingType',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="financeFlowType"
              label={t('bo.orderOverview.searchForm.field.financeFlowType')}
              options={financeFlowTypeOptions}
              controllerProps={{
                name: 'financeFlowType',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="financeApplicationStatus"
              label={t(
                'bo.orderOverview.searchForm.field.financeApplicationStatus'
              )}
              options={financeApplicationStatusOptions}
              controllerProps={{
                name: 'financeApplicationStatus',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="warranty"
              label={t('bo.orderOverview.searchForm.field.warranty')}
              options={warrantyOptions}
              controllerProps={{
                name: 'warranty',
                control
              }}
            />
          </FieldCol>
          <SelectConfirmation
            qaSelector="registrationDocumentsReceived"
            label={t(
              'bo.orderOverview.searchForm.field.registrationDocumentsReceived'
            )}
            controllerProps={{
              name: 'registrationDocumentsReceived',
              control
            }}
          />
          <SelectConfirmation
            qaSelector="carRetailReady"
            label={t('bo.orderOverview.searchForm.field.carRetailReady')}
            controllerProps={{
              name: 'carRetailReady',
              control
            }}
          />
          <SelectConfirmation
            qaSelector="exitCheckState"
            label={t('bo.orderOverview.searchForm.field.exitCheckState')}
            controllerProps={{
              name: 'exitCheckState',
              control
            }}
          />
          <FieldCol>
            <SelectControlled
              allowClear
              qaSelector="overdueBranchDelivery"
              label={t(
                'bo.orderOverview.searchForm.field.overdueBranchDelivery'
              )}
              options={overdueBranchDeliveryOptions}
              controllerProps={{
                name: 'overdueBranchDelivery',
                control
              }}
            />
          </FieldCol>
          <SelectConfirmation
            qaSelector="handoverDateSet"
            label={t('bo.orderOverview.searchForm.field.handoverDateSet')}
            controllerProps={{
              name: 'handoverDateSet',
              control
            }}
          />
          <FieldCol>
            <SelectControlled
              allowClear
              mode="multiple"
              qaSelector="deliveryOption"
              label={t('bo.orderOverview.searchForm.field.deliveryOption')}
              options={deliveryOptions}
              controllerProps={{
                name: 'deliveryOption',
                control
              }}
            />
          </FieldCol>
          <SelectConfirmation
            qaSelector="retailDeliveryETAAvailable"
            label={t(
              'bo.orderOverview.searchForm.field.retailDeliveryETAAvailable'
            )}
            controllerProps={{
              name: 'retailDeliveryETAAvailable',
              control
            }}
          />
          <SelectConfirmation
            qaSelector="carInBranch"
            label={t('bo.orderOverview.searchForm.field.carInBranch')}
            controllerProps={{
              name: 'carInBranch',
              control
            }}
          />
          <FieldCol>
            <SelectControlled
              allowClear
              qaSelector="branchCheckupSuccessful"
              label={t(
                'bo.orderOverview.searchForm.field.branchCheckupSuccessful'
              )}
              options={passedOptions}
              controllerProps={{
                name: 'branchCheckupSuccessful',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <SelectControlled
              allowClear
              qaSelector="overdueMaxEta"
              label={t('bo.orderOverview.table.title.overdueDeliveryEta')}
              options={overdueMaxEtaOptions}
              controllerProps={{
                name: 'overdueMaxEta',
                control
              }}
            />
          </FieldCol>
          <SelectConfirmation
            qaSelector="tradeIn"
            label={t('bo.orderOverview.searchForm.field.tradeIn')}
            controllerProps={{
              name: 'tradeIn',
              control
            }}
          />
          <SelectConfirmation
            qaSelector="cancellationRequested"
            label={t('bo.orderOverview.searchForm.field.cancellationRequested')}
            controllerProps={{
              name: 'cancellationRequested',
              control
            }}
          />
          <FieldCol>
            <SelectControlled
              allowClear
              qaSelector="flowVersion"
              label={t('bo.orderOverview.searchForm.field.flowVersion')}
              options={FLOW_VERSION_OPTIONS}
              controllerProps={{
                name: 'flowVersion',
                control
              }}
            />
          </FieldCol>
          <SalesAgentAssignedTo control={control} />
          <FieldCol>
            <DatePickerRangeControlled
              dateFormat={DATE_FORMAT}
              disabled={isLoading}
              qaSelector="createdAt"
              label={t('bo.orderOverview.searchForm.field.createdAt')}
              controllerProps={{
                name: 'createdAt',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <DatePickerRangeControlled
              dateFormat={DATE_FORMAT}
              disabled={isLoading}
              qaSelector="contractSignedOn"
              label={t('bo.orderOverview.searchForm.field.contractSignedOn')}
              controllerProps={{
                name: 'contractSignedOn',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <DatePickerRangeControlled
              dateFormat={DATE_FORMAT}
              disabled={isLoading}
              qaSelector="pendingVerificationOn"
              label={t(
                'bo.orderOverview.searchForm.field.pendingVerificationOn'
              )}
              controllerProps={{
                name: 'pendingVerificationOn',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <DatePickerRangeControlled
              dateFormat={DATE_FORMAT}
              disabled={isLoading}
              qaSelector="verifiedOn"
              label={t('bo.orderOverview.searchForm.field.verifiedOn')}
              controllerProps={{
                name: 'verifiedOn',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <DatePickerRangeControlled
              dateFormat={DATE_FORMAT}
              disabled={isLoading}
              qaSelector="deliveredOn"
              label={t('bo.orderOverview.searchForm.field.deliveredOn')}
              controllerProps={{
                name: 'deliveredOn',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <DatePickerRangeControlled
              dateFormat={DATE_FORMAT}
              disabled={isLoading}
              qaSelector="completedOn"
              label={t('bo.orderOverview.searchForm.field.completedOn')}
              controllerProps={{
                name: 'completedOn',
                control
              }}
            />
          </FieldCol>
          <FieldCol>
            <DatePickerRangeControlled
              dateFormat={DATE_FORMAT}
              disabled={isLoading}
              qaSelector="canceledOn"
              label={t('bo.orderOverview.searchForm.field.canceledOn')}
              controllerProps={{
                name: 'canceledOn',
                control
              }}
            />
          </FieldCol>
          <SelectConfirmation
            qaSelector="documentRequests"
            label={t('bo.orderOverview.searchForm.field.documentRequests')}
            controllerProps={{
              name: 'documentRequests',
              control
            }}
          />
          <SelectConfirmation
            qaSelector="secondaryWheelsAdded"
            label={t('bo.orderOverview.searchForm.field.secondaryWheelsAdded')}
            controllerProps={{
              name: 'secondaryWheelsAdded',
              control
            }}
          />
          <DaysInInventory
            qaSelector="daysInInventory"
            label={t('bo.orderOverview.searchForm.field.daysInInventory')}
            name="daysInInventory"
            control={control}
            setValue={setValue}
          />
          <OnlyShowMy control={control} refetch={refetch} setValue={setValue} />
          <OnlyShowActive
            control={control}
            refetch={refetch}
            setValue={setValue}
          />
          <Col flex="auto">
            <div className={cn.buttons}>
              <Button
                type="ghost"
                data-qa-selector="reset"
                onClick={onReset}
                icon={<ClearOutlined />}
              />
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={disabled}
                data-qa-selector="submit"
                icon={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
