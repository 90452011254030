import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionModel } from '~/types/OptionModel';

export function useConfirmOptions() {
  const { t } = useTranslation();

  return useMemo(
    (): OptionModel[] => [
      { value: true, label: t('bo.appointment.Yes') },
      { value: false, label: t('bo.appointment.No') }
    ],
    [t]
  );
}
