/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  FullscreenOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons';
import { CSSProperties, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { Loader } from '../Loader';

import cn from './styles.less';
import { calculateScaleToFit } from './utils';

interface Props {
  fullUrl: string;
  fileName: string;
}

export function ImageViewer({ fullUrl, fileName }: Props) {
  const [isImgLoading, setImgLoading] = useState(true);
  const { t } = useTranslation();

  const setImageUrl = useCallback(
    (elem: HTMLImageElement | null) => {
      if (elem && fullUrl) {
        /*
         Fix for Safari. We should add `load` event listener first
          and then set `src` attribute. Otherwise event handler might not work.
        */
        elem.src = fullUrl;
      }
    },
    [fullUrl]
  );

  const style: CSSProperties = isImgLoading
    ? { visibility: 'hidden' }
    : { opacity: 1 };

  return (
    <>
      <TransformWrapper minScale={0.01} centerZoomedOut centerOnInit>
        {({ zoomIn, zoomOut, instance, centerView }) => (
          <>
            <TransformComponent wrapperClass={cn.zoomWrapper}>
              <img
                data-qa-selector="imageViewer"
                ref={setImageUrl}
                className={cn.image}
                alt={fileName ?? t('bo.orderDocuments.noAlt')}
                style={style}
                onLoad={() => {
                  setImgLoading(false);
                  centerView(calculateScaleToFit(instance), 0);
                }}
              />
            </TransformComponent>
            {!isImgLoading && (
              <div data-qa-selector="viewerActions" className={cn.zoomToolbar}>
                <div
                  data-qa-selector="zoomOut"
                  className={cn.zoomToolbarItem}
                  onClick={() => zoomOut()}
                >
                  <ZoomOutOutlined />
                </div>
                <div
                  data-qa-selector="zoomIn"
                  className={cn.zoomToolbarItem}
                  onClick={() => zoomIn()}
                >
                  <ZoomInOutlined />
                </div>
                <div
                  data-qa-selector="fullscreen"
                  className={cn.zoomToolbarItem}
                  onClick={() => {
                    centerView(calculateScaleToFit(instance), 0);
                  }}
                >
                  <FullscreenOutlined />
                </div>
              </div>
            )}
          </>
        )}
      </TransformWrapper>
      {isImgLoading && <Loader />}
    </>
  );
}
