import {
  createFilterPayload,
  createBaseFilter,
  FilterType
} from '@retail/gql-utils';

import { useSearchOrdersLazyQuery } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

export const useOrderById = () => {
  const [fetchData, { loading }] = useSearchOrdersLazyQuery({
    ...REQUEST_BASE_OPTIONS
  });

  const doFetch = (id: string) =>
    fetchData({
      variables: {
        search: createFilterPayload({
          filter: createBaseFilter('id', FilterType.EQUAL, id),
          page: 0,
          pageSize: 20,
          sorts: []
        })
      }
    }).then((res) => res.data?.searchOrders?.entities?.[0]);

  return {
    isLoading: loading,
    doFetch
  };
};
