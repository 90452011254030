import { SortType } from '@retail/gql-utils';
import { TableColumnType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SortOrder } from '~/constants/SortOrder';
import { FEATURE } from '~/constants/feature';
import { useFeatures } from '~/hooks/useFeatures';
import { useUser } from '~/hooks/useUser';
import { EsOrderModel } from '~/types/EsOrderModel';

import {
  ORDER_OVERVIEW_COLUMN_NAME_TO_TRANSLATION_KEY,
  OrderOverviewColumnName
} from '../constants';
import { ColumnConfig, OrderOverviewSearchModel } from '../types';
import { COLUMN_NAME_TO_CONFIG } from '../utils/columnNameToConfig';
import { priorityClassName } from '../utils/priorityClassName';

interface Props {
  controller: OrderOverviewSearchModel;
  columnConfig: ColumnConfig[];
}

export function useColumns({
  controller: { sort, direction },
  columnConfig
}: Props) {
  const { t } = useTranslation();
  const { country } = useUser();
  const { isFeatureEnabled } = useFeatures({
    names: [FEATURE.ORDERS_PENDING_VERIFICATION_COLOR_CODING],
    country
  });

  return useMemo(() => {
    function mapConfigToColumn(key: string) {
      let config = COLUMN_NAME_TO_CONFIG[key]({ t });

      if (
        key === OrderOverviewColumnName.Priority &&
        isFeatureEnabled(FEATURE.ORDERS_PENDING_VERIFICATION_COLOR_CODING)
      ) {
        config = {
          ...config,
          onCell: priorityClassName
        };
      }

      return {
        ...config,
        dataIndex: config?.dataIndex?.toString().split(/[.,]/g),
        title: t(ORDER_OVERVIEW_COLUMN_NAME_TO_TRANSLATION_KEY[key])
      };
    }

    const columns = columnConfig
      .map((x) => (x.isVisible ? mapConfigToColumn(x.name) : null))
      .filter(Boolean)
      /** @description column Actions has to be last, a user won't be able to edit it */
      .concat(mapConfigToColumn(OrderOverviewColumnName.Id));

    return columns.map((column: TableColumnType<EsOrderModel>) => {
      const sortOrder =
        direction === SortType.ASC ? SortOrder.Ascend : SortOrder.Descend;
      const defaultSortOrder = column.dataIndex === sort ? sortOrder : null;

      return {
        ...column,
        defaultSortOrder
      };
    });
  }, [columnConfig, direction, isFeatureEnabled, sort, t]);
}
