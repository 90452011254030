/* eslint-disable no-restricted-imports */
import {
  filter,
  flow,
  get,
  includes,
  intersection,
  isEmpty,
  last,
  map,
  size,
  without
} from 'lodash/fp';

import { AUX_USER_ACCESS_PARAM_VALUE } from '~/constants/auxUser/accessParams';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

export function hasPermissions(userPerms: string[], allowPerms: string[]) {
  const result = allowPerms.filter((perm) => userPerms.includes(perm));

  return Boolean(result.length);
}

export function mapToGQLPermission(perm: PERMISSION, domain: DOMAIN) {
  return domain.includes('clm')
    ? `${domain}/${perm}`
    : `gqls://${domain}/${perm}`;
}

export function getGQLPermissions(
  allow: PERMISSION | PERMISSION[],
  domain: DOMAIN | DOMAIN[]
) {
  const perms = Array.isArray(allow) ? allow : [allow];

  return perms.map((perm, i) =>
    mapToGQLPermission(perm, Array.isArray(domain) ? domain[i] : domain)
  );
}

const minSize = 2;
const modifiers = [
  AUX_USER_ACCESS_PARAM_VALUE.NONE,
  AUX_USER_ACCESS_PARAM_VALUE.ALL
];

export const parseAccessParams = (value) => {
  if (isEmpty(value) || size(value) < minSize) {
    return value;
  }

  const newValue = last(value);

  if (includes(newValue, modifiers)) {
    return [newValue];
  }

  if (intersection(modifiers, value)) {
    return without(modifiers, value);
  }

  return value;
};

export const hasAccess = (value, userAccess): boolean => {
  if (includes(AUX_USER_ACCESS_PARAM_VALUE.ALL, userAccess)) {
    return true;
  }

  if (includes(AUX_USER_ACCESS_PARAM_VALUE.NONE, userAccess)) {
    return false;
  }

  return includes(value, userAccess) as unknown as boolean; //Todo: check typing for better solution
};

export const getUserAccess = (key) => {
  return flow(filter({ key }), map(get(['value'])));
};
