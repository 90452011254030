// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UMslOouVgl2b6F9iMeEx {\n  display: flex;\n  align-items: end;\n  gap: 8px;\n}\n.MCbL7umfROSHlIxIhbb7 {\n  overflow: visible;\n  white-space: nowrap;\n}\n.vNM3xNTZ8ouZKAn9Ta6X {\n  min-height: 24px;\n}\n.vNM3xNTZ8ouZKAn9Ta6X .antd-4-21-7-form-item-control-input {\n  background-color: red !important;\n  min-height: 0px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OrderOverview/SearchForm/DaysInInventory/style.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gBAAA;EACA,QAAA;AADF;AAIA;EACE,iBAAA;EACA,mBAAA;AAFF;AAKA;EACE,gBAAA;AAHF;AAOM;EACE,gCAAA;EACA,0BAAA;AALR","sourcesContent":["@import '../../../../styles/constants.less';\n\n.section {\n  display: flex;\n  align-items: end;\n  gap: 8px;\n}\n\n.label {\n  overflow: visible;\n  white-space: nowrap;\n}\n\n.message {\n  min-height: 24px;\n\n  :global {\n    .@{antd-version} {\n      &-form-item-control-input {\n        background-color: red !important;\n        min-height: 0px !important;\n      }\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "UMslOouVgl2b6F9iMeEx",
	"label": "MCbL7umfROSHlIxIhbb7",
	"message": "vNM3xNTZ8ouZKAn9Ta6X"
};
export default ___CSS_LOADER_EXPORT___;
