import { Card, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSecondaryWheelsUpsellOptionsQuery } from '~/apollo/gql-types';

import { ActionButton } from './ActionButton';
import { useColumns } from './useColumns';
import { withFFUpsellSecondSetOfWheelsShow } from './withFFUpsellSecondSetOfWheelsShow';

export interface SecondaryWheelsUpsellProps {
  orderId: string;
  country: string;
}

export const SecondaryWheelsUpsell =
  withFFUpsellSecondSetOfWheelsShow<SecondaryWheelsUpsellProps>(
    ({ orderId }) => {
      const { t } = useTranslation();
      const { data, loading, called } = useSecondaryWheelsUpsellOptionsQuery({
        variables: { orderId }
      });
      const columns = useColumns();

      if (called && !loading && !data?.secondaryWheelsUpsellOptions?.length) {
        return null;
      }

      return (
        <Card
          size="small"
          title={t('bo.summaryVerificationSecondaryWheelsUpsell.table.title')}
          extra={
            <ActionButton
              orderId={orderId}
              /**
               * @description BE will always return one item
               */
              secondaryWheelsId={data?.secondaryWheelsUpsellOptions?.[0]?.id}
            />
          }
        >
          <Table
            size="small"
            rowKey="id"
            scroll={{ x: 0 }}
            columns={columns}
            dataSource={data?.secondaryWheelsUpsellOptions}
            pagination={false}
            loading={loading}
          />
        </Card>
      );
    }
  );
