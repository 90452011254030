import { FunctionComponent } from 'react';

import { FEATURE } from '~/constants/feature';
import { useFeatures } from '~/hooks/useFeatures';

interface Props {
  country: string;
}

export function withFFUpsellSecondSetOfWheelsShow<T extends Props>(
  As: FunctionComponent<T>
) {
  return (props: T) => {
    const { isFeatureEnabled } = useFeatures({
      names: [FEATURE.UPSELL_SECOND_SET_OF_WHEELS_SHOW],
      country: props.country
    });

    return isFeatureEnabled(FEATURE.UPSELL_SECOND_SET_OF_WHEELS_SHOW) ? (
      <As {...props} />
    ) : null;
  };
}
