import { SelectControlled } from '@retail/backoffice-ui';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useOrderStateOptions } from '../hooks/useOrderStateOptions';
import { OrderOverviewSearchModel } from '../types';

import { FieldCol } from './FieldCol';

interface Props {
  control: Control<OrderOverviewSearchModel>;
}

export function OrderState({ control }: Props) {
  const { t } = useTranslation();
  const stateOptions = useOrderStateOptions();
  const isOnlyShowActive = useWatch({ control, name: 'isOnlyShowActive' });

  function checker(value: string[]) {
    return isOnlyShowActive ? [] : value;
  }

  return (
    <FieldCol>
      <SelectControlled
        allowClear
        mode="multiple"
        qaSelector="state"
        label={t('bo.orderOverview.searchForm.field.state')}
        options={stateOptions}
        checker={checker}
        disabled={isOnlyShowActive}
        controllerProps={{
          name: 'state',
          control
        }}
      />
    </FieldCol>
  );
}
