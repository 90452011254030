// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yt29JiyzdoD8VIScXq_W.antd-4-21-7-btn-primary {\n  background-color: #e87f36;\n  border-color: #e87f36;\n}\n.Yt29JiyzdoD8VIScXq_W.antd-4-21-7-btn-primary:hover,\n.Yt29JiyzdoD8VIScXq_W.antd-4-21-7-btn-primary:focus {\n  background-color: #f2a268;\n  border-color: #f2a268;\n}\n.Yt29JiyzdoD8VIScXq_W.antd-4-21-7-btn-primary:active {\n  background-color: #bf5e2a;\n  border-color: #bf5e2a;\n}\n.Yt29JiyzdoD8VIScXq_W.antd-4-21-7-btn-primary[disabled] {\n  color: #fff;\n  opacity: 0.75;\n}\n", "",{"version":3,"sources":["webpack://./src/components/BrandButton/styles.local.less"],"names":[],"mappings":"AAGE;EACE,yBAAA;EACA,qBAAA;AAFJ;AAKE;;EAEE,yBAAA;EACA,qBAAA;AAHJ;AAME;EACE,yBAAA;EACA,qBAAA;AAJJ;AAOE;EACE,WAAA;EACA,aAAA;AALJ","sourcesContent":["@import '~styles/definitions.less';\n\n.brandButton {\n  &:global(.@{antd-version}-btn-primary) {\n    background-color: @brand-primary;\n    border-color: @brand-primary;\n  }\n\n  &:global(.@{antd-version}-btn-primary:hover),\n  &:global(.@{antd-version}-btn-primary:focus) {\n    background-color: #f2a268;\n    border-color: #f2a268;\n  }\n\n  &:global(.@{antd-version}-btn-primary:active) {\n    background-color: #bf5e2a;\n    border-color: #bf5e2a;\n  }\n\n  &:global(.@{antd-version}-btn-primary)[disabled] {\n    color: #fff;\n    opacity: 0.75;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandButton": "Yt29JiyzdoD8VIScXq_W"
};
export default ___CSS_LOADER_EXPORT___;
