import {
  createBaseFilter,
  createFilter,
  createFilterPayload,
  FilterType,
  Sorts
} from '@retail/gql-utils';

import { useReferenceOrdersByCustomerEmailLazyQuery } from '~/apollo/gql-types';
import { OrderState } from '~/constants/OrderState';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

const standardOrderAvailableStatesForReferencingToAdditional = [
  OrderState.COMPLETED,
  OrderState.DELIVERED,
  OrderState.VERIFIED,
  OrderState.CANCELED
];

export const useOrderReferenceOptions = () => {
  const [fetchData, { data, loading }] =
    useReferenceOrdersByCustomerEmailLazyQuery({
      ...REQUEST_BASE_OPTIONS
    });

  const doFetch = (email: string) =>
    fetchData({
      variables: {
        search: createFilterPayload({
          filter: createFilter(null, FilterType.AND, [
            createFilter(
              'state',
              FilterType.IN,
              standardOrderAvailableStatesForReferencingToAdditional
            ),
            createBaseFilter('customer.email', FilterType.LIKE, email.trim())
          ]),
          page: 0,
          pageSize: 20,
          sorts: [Sorts.desk('createdAt')]
        })
      }
    });

  return {
    isLoading: loading,
    data:
      data?.esSearchOrders?.entities?.map((it) => ({
        value: it.id,
        label: it.orderNumber
      })) ?? [],
    doFetch
  };
};
