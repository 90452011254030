import * as ls from 'local-storage';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { DOMAIN } from '~/constants/domain';
import { FEATURE } from '~/constants/feature';
import { PERMISSION } from '~/constants/permission';
import { useCheckPermissions } from '~/hooks/useCheckPermissions';
import { useFeatures } from '~/hooks/useFeatures';
import { useUser } from '~/hooks/useUser';

import { DEFAULT_ORDER_OVERVIEW_COLUMN_CONFIG } from '../constants';
import { ColumnConfig } from '../types';
import { COLUMN_NAME_TO_PERMISSION_CHECKER } from '../utils/columnNameToPermissionChecker';

const STORAGE_KEY_PREFIX = 'orderOverviewColumnConfig:';

export function useColumnsConfig() {
  const { id, country } = useUser();
  const [config, setConfig] = useState(DEFAULT_ORDER_OVERVIEW_COLUMN_CONFIG);
  /** @description without id a user wont be able to see this page */
  const storageKey = `${STORAGE_KEY_PREFIX}${id}`;
  const { isFeatureEnabled, isLoading } = useFeatures({
    names: [FEATURE.SALES_AGENTS_IRRELEVANT_COLUMNS],
    country
  });
  const isEnabledSalesAgentsIrrelevantColums = isFeatureEnabled(
    FEATURE.SALES_AGENTS_IRRELEVANT_COLUMNS
  );
  const { isAllowed: isAllowedOrderSalesAgentAssignees } = useCheckPermissions({
    allow: PERMISSION.ORDER_SALES_AGENT_ASSIGNEES,
    domain: DOMAIN.ORDER_MANAGEMENT
  });
  const { isAllowed: isCustomerLeadAssignable } = useCheckPermissions({
    allow: PERMISSION.CUSTOMER_LEAD_ASSIGNABLE,
    domain: DOMAIN.CLM_GRANT
  });

  useLayoutEffect(() => {
    const value = ls.get<ColumnConfig[] | null>(storageKey) ?? [];
    const valueToStore = value.slice().sort((a, b) => a.order - b.order);

    if (valueToStore.length) {
      setConfig(valueToStore);
    }
  }, [storageKey]);

  const handleConfig = useCallback(
    (value: ColumnConfig[]) => {
      const valueToSave = value.slice().map((x, order) => ({ ...x, order }));

      setConfig(valueToSave);
      ls.set<ColumnConfig[] | null>(storageKey, valueToSave);
    },
    [storageKey]
  );

  const userConfig = useMemo(() => {
    /**
     * @description sometimes we may have more columns in DEFAULT_ORDER_OVERVIEW_COLUMN_CONFIG than in config (its data from storage),
     * because of that we have to be sure that users have all available colums
     */
    const availableConfigMap = new Map<string, ColumnConfig>();

    DEFAULT_ORDER_OVERVIEW_COLUMN_CONFIG.forEach((x) => {
      const checker = COLUMN_NAME_TO_PERMISSION_CHECKER[x.name];
      /** @description in case we are loading data we should not use any checks */
      const isAllowed = isLoading
        ? false
        : checker?.({
            isAllowedOrderSalesAgentAssignees,
            isCustomerLeadAssignable,
            isEnabledSalesAgentsIrrelevantColums
          });

      /** @description in case we dont have checker we should skip checking */
      if (checker ? isAllowed : true) {
        availableConfigMap.set(x.name, x);
      }
    });

    const filteredConfig = config.filter((x) => {
      if (availableConfigMap.has(x.name)) {
        availableConfigMap.delete(x.name);
        return true;
      }

      return false;
    });

    const availableConfig = Array.from(availableConfigMap.values()).map(
      (x, index) => ({
        ...x,
        order: filteredConfig.length + index
      })
    );

    return filteredConfig.concat(...availableConfig);
  }, [
    config,
    isAllowedOrderSalesAgentAssignees,
    isCustomerLeadAssignable,
    isEnabledSalesAgentsIrrelevantColums,
    isLoading
  ]);

  return [userConfig, handleConfig] as const;
}
