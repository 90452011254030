import * as FinanceApplicationStatus from '@retail/order-financing/src/constants/financingStatuses/enum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useFinanceApplicationStatusOptions() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.values(FinanceApplicationStatus).map((value) => ({
        value,
        label: t(`bo.taskManagement.financingStatuses.${value}`)
      })),
    [t]
  );
}
