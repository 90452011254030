import { OptionModel } from '@retail/backoffice-ui';
import { ORDER_TYPES_VALUES } from '@retail/order-constants/src/types';
import { useTranslation } from 'react-i18next';

export const ORDER_TYPE_KEY_PREFIX = 'bo.orderOverview.searchForm.options.type';

export function useOrderTypeOptions() {
  const { t } = useTranslation('', { keyPrefix: ORDER_TYPE_KEY_PREFIX });

  return ORDER_TYPES_VALUES.map(
    (value): OptionModel => ({ value, label: t(value) })
  );
}
