import { useCallback } from 'react';

import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import {
  getGQLPermissions,
  hasPermissions
} from '~/hooks/useCheckPermissions/utils';
import { useUser } from '~/hooks/useUser';

export function useHasPermissions() {
  const permissionsData = useUser();

  return useCallback(
    ({
      allow,
      domain
    }: {
      allow: PERMISSION | PERMISSION[];
      domain: DOMAIN | DOMAIN[];
    }) => {
      const { permissions, isLoaded } = permissionsData;
      const requestedPerms = getGQLPermissions(allow, domain);
      const hasRequestedPermissions = hasPermissions(
        permissions,
        requestedPerms
      );
      return isLoaded && hasRequestedPermissions;
    },
    [permissionsData]
  );
}
