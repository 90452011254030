import { PATHS } from '@retail/backoffice-urls';
import { PathRouteProps } from 'react-router-dom';

import { OrderCreate } from './pages/OrderCreate';
import { OrderOverview } from './pages/OrderOverview';

export const ROUTES: PathRouteProps[] = [
  {
    path: PATHS.ORDERS,
    element: <OrderOverview />
  },
  {
    path: PATHS.CREATE_ORDER,
    element: <OrderCreate />
  }
];
