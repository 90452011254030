import { ApolloError } from '@apollo/client/errors';
import {
  ITEM_EXTERNAL_TYPES,
  ADDITIONAL_ORDER_CREATE_REASONS
} from '@retail/order-constants';
import {
  ORDER_TYPES,
  ORDER_TYPES_VALUES
} from '@retail/order-constants/src/types';
import { useCallback } from 'react';

import {
  OrderCreateProjectionInput,
  RetailAdProjection,
  useCreateAdditionalFeesCollectionOrderMutation,
  useCreateAdditionalItemsPurchaseOrderMutation,
  useCreateOtherOrderMutation,
  useCreateStandardOrderMutation
} from '~/apollo/gql-types';

interface CreateOrderByTypeParams {
  retailCountry: string;
  type: typeof ORDER_TYPES_VALUES[number];
  userId: string;
  linkedOrderReason?: string;
  linkedOrderId?: string;
  orderVersion: string;
  conversionType: string;
  checkoutBranchId: number;
  isAhCheckoutByAgent?: boolean;
}

interface Props {
  retailAdInfo: Partial<RetailAdProjection> | null;
  onError: (error: ApolloError) => void;
}

export const useCreateOrderByType = ({ retailAdInfo, onError }: Props) => {
  const [
    createStandardOrderMutation,
    { loading: createStandardOrderMutationLoading }
  ] = useCreateStandardOrderMutation({
    onError
  });

  const [
    createAdditionalFeesCollectionOrderMutation,
    { loading: createAdditionalFeesCollectionOrderMutationLoading }
  ] = useCreateAdditionalFeesCollectionOrderMutation({
    onError
  });

  const [
    createAdditionalItemsPurchaseOrderMutation,
    { loading: createAdditionalItemsPurchaseOrderMutationLoading }
  ] = useCreateAdditionalItemsPurchaseOrderMutation({
    onError
  });

  const [
    createOtherOrderMutation,
    { loading: createOtherOrderMutationLoading }
  ] = useCreateOtherOrderMutation({
    onError
  });

  const createOrderByType = useCallback(
    async ({
      retailCountry,
      userId,
      linkedOrderId,
      linkedOrderReason,
      type,
      orderVersion,
      conversionType,
      checkoutBranchId,
      isAhCheckoutByAgent
    }: CreateOrderByTypeParams) => {
      const order: OrderCreateProjectionInput = {
        retailCountry,
        conversionType,
        checkoutBranchId,
        isAhCheckoutByAgent,
        auxUserId: userId,
        ...(type === ORDER_TYPES.ADDITIONAL
          ? {
              retailOrderLink: {
                refId: linkedOrderId
              }
            }
          : {}),
        items: retailAdInfo
          ? [
              {
                externalId: retailAdInfo.adId,
                externalType: ITEM_EXTERNAL_TYPES.AD,
                priceGrossMinorUnits: retailAdInfo.grossPrice.amountMinorUnits
              }
            ]
          : [],
        orderAddresses: [], // TODO: remove when BE is ready
        flowVersion: orderVersion
      };

      switch (type) {
        case ORDER_TYPES.STANDARD:
          return (
            await createStandardOrderMutation({
              variables: {
                order
              }
            })
          )?.data?.createStandardOrder?.id;
        case ORDER_TYPES.ADDITIONAL:
          return linkedOrderReason ===
            ADDITIONAL_ORDER_CREATE_REASONS.FEES_COLLECTION
            ? (
                await createAdditionalFeesCollectionOrderMutation({
                  variables: {
                    order
                  }
                })
              )?.data?.createAdditionalFeesCollectionOrder?.id
            : (
                await createAdditionalItemsPurchaseOrderMutation({
                  variables: {
                    order
                  }
                })
              )?.data?.createAdditionalItemsPurchaseOrder?.id;

        case ORDER_TYPES.OTHER:
          return (
            await createOtherOrderMutation({
              variables: {
                order
              }
            })
          )?.data?.createOtherOrder?.id;
      }
    },
    [
      retailAdInfo,
      createStandardOrderMutation,
      createAdditionalFeesCollectionOrderMutation,
      createAdditionalItemsPurchaseOrderMutation,
      createOtherOrderMutation
    ]
  );
  const isLoading =
    createStandardOrderMutationLoading ||
    createAdditionalFeesCollectionOrderMutationLoading ||
    createAdditionalItemsPurchaseOrderMutationLoading ||
    createOtherOrderMutationLoading;

  return [createOrderByType, isLoading] as const;
};
