import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ColorCoding } from '~/apollo/gql-types';

interface Props {
  stateColorCoding: ColorCoding;
}

export function CancellationRequestedOrderStateTooltip({
  stateColorCoding
}: Props) {
  const { t } = useTranslation();

  return stateColorCoding === ColorCoding.Grey ? (
    <Tooltip
      title={t('bo.orderOverview.orderState.cancellationRequested.tooltip')}
    >
      <ExclamationCircleOutlined data-qa-selector="cancellationRequestedOrderStateIcon" />
    </Tooltip>
  ) : null;
}
