import { Button, ButtonProps } from 'antd';
import cns from 'classnames';

import cn from './styles.local.less';

interface BrandButtonProps extends ButtonProps {
  className?: string;
}

export const BrandButton = ({ className, ...props }: BrandButtonProps) => {
  return <Button className={cns(className, cn.brandButton)} {...props} />;
};
