import { OptionModel, SelectControlled } from '@retail/backoffice-ui';
import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useOrderSalesAgentAssigneesQuery } from '~/apollo/gql-types';
import { withPermissions } from '~/components/PermissionChecker';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { useEnabledFullName } from '~/hooks/useEnabledFullName';

import { OrderOverviewSearchModel } from '../types';

import { FieldCol } from './FieldCol';

interface Props {
  control: Control<OrderOverviewSearchModel>;
}

export const SalesAgentAssignedTo = withPermissions<Props>(
  PERMISSION.ORDER_SALES_AGENT_ASSIGNEES,
  DOMAIN.ORDER_MANAGEMENT
)(({ control }) => {
  const { t } = useTranslation();
  const getEnabledFullName = useEnabledFullName();
  const { data, loading } =
    useOrderSalesAgentAssigneesQuery(REQUEST_BASE_OPTIONS);
  const isOnlyShowMy = useWatch({ control, name: 'isOnlyShowMy' });
  const options = useMemo(
    () =>
      data?.orderSalesAgentAssignees?.map(
        ({ id, ...user }): OptionModel => ({
          label: getEnabledFullName(user),
          value: id
        })
      ) ?? [],
    [data?.orderSalesAgentAssignees, getEnabledFullName]
  );

  function checker(value: string[]) {
    return isOnlyShowMy ? [] : value;
  }

  return (
    <FieldCol>
      <SelectControlled
        allowClear
        mode="multiple"
        qaSelector="salesAgentAssignedTo"
        loading={loading}
        disabled={isOnlyShowMy}
        label={t('bo.orderOverview.searchForm.field.salesAgentAssignedTo')}
        options={options}
        checker={checker}
        controllerProps={{
          name: 'salesAgentAssignedTo',
          control
        }}
      />
    </FieldCol>
  );
});
