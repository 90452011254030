// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Fj6C1UI6bAs7Pr9Ffsdi {\n  background-color: rgba(0, 255, 0, 0.2) !important;\n}\n.tlG0ekaasnPhcoijPZqc {\n  background-color: rgba(255, 255, 0, 0.2) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OrderOverview/utils/priorityClassName/styles.less"],"names":[],"mappings":"AAEA;EACE,iDAAA;AADF;AAIA;EACE,mDAAA;AAFF","sourcesContent":["@opacity: 0.2;\n\n.greenRow {\n  background-color: rgba(0, 255, 0, @opacity) !important;\n}\n\n.yellowRow {\n  background-color: rgba(255, 255, 0, @opacity) !important;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greenRow": "Fj6C1UI6bAs7Pr9Ffsdi",
	"yellowRow": "tlG0ekaasnPhcoijPZqc"
};
export default ___CSS_LOADER_EXPORT___;
