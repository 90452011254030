import {
  ControlledPositiveIntegerInput,
  FormItem
} from '@retail/backoffice-ui';
import { useEffect } from 'react';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldCol } from '../FieldCol';

import cn from './style.less';

interface Props {
  qaSelector: string;
  control: Control;
  label: string;
  name: string;
  setValue: UseFormSetValue<FieldValues>;
}

export function DaysInInventory({ qaSelector, control, label, name }: Props) {
  const { t } = useTranslation();
  const { setError, clearErrors, setValue } = useFormContext();
  const [from, to] = useWatch({
    control,
    name
  });

  useEffect(() => {
    if (Number.isFinite(from) && Number.isFinite(to) && from > to) {
      setError(name, {
        message: t(
          'bo.orderOverview.searchForm.field.daysInInventory.errorMessage'
        )
      });
    } else {
      clearErrors(name);
      /** revalidate */
      setValue(name, [from, to], { shouldValidate: true });
    }
  }, [clearErrors, from, name, setError, setValue, t, to]);

  return (
    <FieldCol data-qa-selector={qaSelector}>
      <div className={cn.section}>
        <ControlledPositiveIntegerInput
          labelCol={{ span: undefined }}
          parse={parseInt}
          label={<span className={cn.label}>{label}</span>}
          placeholder={t('bo.common.from')}
          autoComplete="off"
          allowClear
          controllerProps={{
            name: `${name}[0]`,
            control
          }}
        />
        <ControlledPositiveIntegerInput
          labelCol={{ span: undefined }}
          parse={parseInt}
          placeholder={t('bo.common.to')}
          autoComplete="off"
          allowClear
          controllerProps={{
            name: `${name}[1]`,
            control
          }}
        />
      </div>
      <FormItem controllerProps={{ control, name }} className={cn.message} />
    </FieldCol>
  );
}
