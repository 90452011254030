import { createFilterPayload, Filters } from '@retail/gql-utils';

import { useRetailsAdInfoForOrderCreationQuery } from '~/apollo/gql-types';

export const useRetailAdInfo = ({ stockNumber }: { stockNumber: string }) => {
  const { data } = useRetailsAdInfoForOrderCreationQuery({
    skip: !stockNumber,
    variables: {
      search: createFilterPayload({
        filter: Filters.equal('stockNumber', stockNumber),
        pageSize: 1,
        page: 0
      })
    }
  });

  return data?.ads?.entities?.[0] ?? null;
};
