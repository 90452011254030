import { ORDER_TYPES } from '@retail/order-constants/src/types';
import { useTranslation } from 'react-i18next';

import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { useHasPermissions } from '~/hooks/useHasPermissions';

export const useCreationOrderTypeOptions = () => {
  const { t } = useTranslation();
  const hasPermissions = useHasPermissions();

  return [
    {
      value: ORDER_TYPES.STANDARD,
      label: t('bo.orderOverview.orderTypeForm.typeOptions.standard'),
      isAvailable: true
    } as const,
    {
      value: ORDER_TYPES.ADDITIONAL,
      label: t('bo.orderOverview.orderTypeForm.typeOptions.additional'),
      isAvailable: hasPermissions({
        allow: [
          PERMISSION.MUTATION_CREATE_ADDITIONAL_FEES_COLLECTION_ORDER,
          PERMISSION.MUTATION_CREATE_ADDITIONAL_ITEMS_PURCHASE_ORDER
        ],
        domain: DOMAIN.ORDER_MANAGEMENT
      })
    } as const,
    {
      value: ORDER_TYPES.OTHER,
      label: t('bo.orderOverview.orderTypeForm.typeOptions.other'),
      isAvailable: hasPermissions({
        allow: PERMISSION.MUTATION_CREATE_OTHER_ORDER,
        domain: DOMAIN.ORDER_MANAGEMENT
      })
    } as const
  ].filter((it) => it.isAvailable);
};
