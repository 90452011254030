import { Button, Checkbox, TableColumnType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnConfig } from '../types';

import { DragHandle } from './DragHandle';
import cn from './styles.less';

export function useColumns(
  onVisibleChange: (name: string, value: boolean) => void
) {
  const { t } = useTranslation();

  return useMemo(
    (): TableColumnType<ColumnConfig>[] => [
      {
        title: t('bo.orderOverview.columnSetting.table.fieldName'),
        dataIndex: 'title',
        key: 'name',
        render: (value, { name }) => (
          <div
            data-qa-selector={`fieldName-${name}`}
            className={cn.fieldNameColumn}
          >
            <Button
              className={cn.fieldNameColumnButton}
              size="small"
              icon={<DragHandle name={name} />}
            />
            {value}
          </div>
        )
      },
      {
        title: t('bo.orderOverview.columnSetting.table.isVisible'),
        dataIndex: 'isVisible',
        className: cn.cellIsVisible,
        width: 100,
        render: (value, { name }) => (
          <Checkbox
            data-qa-selector={`checkbox-${name}`}
            checked={value}
            value={value}
            onChange={(x) => onVisibleChange(name, x.target.checked)}
          />
        )
      }
    ],
    [onVisibleChange, t]
  );
}
