import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import FileSaver from 'file-saver';

interface Props {
  currentDocUrl: string;
  fileName: string;
}

export function DownloadButton({ currentDocUrl, fileName }: Props) {
  function onDownload() {
    if (!currentDocUrl || !fileName) {
      return;
    }

    try {
      FileSaver.saveAs(currentDocUrl, fileName);
    } catch (error) {
      console.error('Error while saving doc: ', error);
      global.window.open(currentDocUrl, '_blank');
    }
  }

  return (
    <>
      {currentDocUrl ? (
        <Button
          data-qa-selector="downloadButton"
          size="large"
          icon={<DownloadOutlined />}
          onClick={onDownload}
        />
      ) : null}
    </>
  );
}
