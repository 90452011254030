import { CONVERSION_TYPES } from '@retail/order-constants';

export const CONVERSION_TYPE_OPTIONS = [
  {
    value: CONVERSION_TYPES.BRANCH_CHECKOUT,
    label: 'bo.options.order.conversionType.branchCheckout'
  },
  {
    value: CONVERSION_TYPES.REP_CHECKOUT,
    label: 'bo.options.order.conversionType.repCheckout'
  },
  {
    value: CONVERSION_TYPES.ONLINE_CHECKOUT,
    label: 'bo.options.order.conversionType.onlineCheckout'
  }
];
