import { QuestionCircleOutlined } from '@ant-design/icons';
import { KeyValueRow } from '@retail/backoffice-ui';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { NAMESPACE } from '~/i18n/constants';

interface Props {
  cancelType: string;
  cancelPeriod: string;
  cancelReason: string;
  subCancelReason: string;
  cancelDescription: string;
}

export function CanceledOrderStateTooltip({
  cancelType,
  cancelPeriod,
  cancelReason,
  subCancelReason,
  cancelDescription
}: Props) {
  const { t: labelT } = useTranslation(NAMESPACE, {
    keyPrefix: 'bo.orderOverview.cancelOrder.tooltip.label'
  });

  return (
    <Tooltip
      title={
        <div data-qa-selector="canceledOrderStateTooltip">
          <KeyValueRow label={labelT('cancelType')} value={cancelType} />
          <KeyValueRow label={labelT('cancelPeriod')} value={cancelPeriod} />
          <KeyValueRow label={labelT('cancelReason')} value={cancelReason} />
          <KeyValueRow
            label={labelT('subCancelReason')}
            value={subCancelReason}
          />
          <KeyValueRow
            label={labelT('cancelDescription')}
            value={cancelDescription}
          />
        </div>
      }
    >
      <QuestionCircleOutlined data-qa-selector="canceledOrderStateIcon" />
    </Tooltip>
  );
}
