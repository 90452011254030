import { DragOutlined } from '@ant-design/icons';
import { SortableHandle } from 'react-sortable-hoc';

interface Props {
  name: string;
}

export const DragHandle = SortableHandle<Props>(({ name }) => (
  <DragOutlined
    data-qa-selector={`dragHandle-${name}`}
    style={{ cursor: 'grab' }}
  />
));
