import { useMemo } from 'react';

import { useLocalizedOptions } from '~/hooks/useLocalizedOptions';
import { useHasPermissions } from '~/hooks/useHasPermissions';
import { PERMISSION } from '~/constants/permission';
import { DOMAIN } from '~/constants/domain';

export const useAdditionalOrderCreationReasonOptions = () => {
  const hasPermissions = useHasPermissions();

  const options = useMemo(
    () =>
      [
        {
          value: 'FEES_COLLECTION',
          label: 'bo.orderOverview.orderTypeForm.reasonOption.chargingFees',
          visible: hasPermissions({
            allow: PERMISSION.MUTATION_CREATE_ADDITIONAL_FEES_COLLECTION_ORDER,
            domain: DOMAIN.ORDER_MANAGEMENT
          })
        },
        {
          value: 'ITEMS_PURCHASE',
          label:
            'bo.orderOverview.orderTypeForm.reasonOption.sellingAdditionalItems',
          visible: hasPermissions({
            allow: PERMISSION.MUTATION_CREATE_ADDITIONAL_ITEMS_PURCHASE_ORDER,
            domain: DOMAIN.ORDER_MANAGEMENT
          })
        }
      ].filter((option) => option.visible),
    [hasPermissions]
  );

  return useLocalizedOptions(options);
};
