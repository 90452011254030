import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import { FunctionComponent, PropsWithChildren } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { GQL_CLIENT } from '~/apollo/gql-client';
import { ANTD_EXTERNAL_VERSION_LESS } from '~/constants/antd';

import { UserProvider } from './UserProvider';

export function RootProvider({ children }: PropsWithChildren<unknown>) {
  return (
    <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
      <ApolloProvider client={GQL_CLIENT}>
        <UserProvider>{children}</UserProvider>
      </ApolloProvider>
    </ConfigProvider>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function withRootProvider<T extends {}>(As: FunctionComponent<T>) {
  return (props: T) => (
    <RootProvider>
      <As {...props} />
    </RootProvider>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function withRootRouteProvider<T extends {}>(As: FunctionComponent<T>) {
  return (props: T) => (
    <RootProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<As {...props} />} />
        </Routes>
      </BrowserRouter>
    </RootProvider>
  );
}
