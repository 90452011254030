/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Dispatch } from 'react';

import cn from './styles.less';

interface Props {
  onClick: Dispatch<number>;
}

export const Arrows = ({ onClick }: Props) => {
  return (
    <>
      <div
        data-qa-selector="leftArrow"
        className={cn.arrowPrevious}
        onClick={() => onClick(-1)}
      >
        <ArrowLeftOutlined />
      </div>
      <div
        data-qa-selector="rightArrow"
        className={cn.arrowNext}
        onClick={() => onClick(+1)}
      >
        <ArrowRightOutlined />
      </div>
    </>
  );
};
