import {
  getCountry,
  getDefaultLocaleOfCountry,
  toValidLocale
} from '@retail/i18n/utils';
import { useCallback, useEffect, useState } from 'react';
import { DefaultValues } from 'react-hook-form';

import { CustomerForm, CustomerFormVM } from './CustomerForm';
import {
  CustomerSearchForm,
  CustomerSearchFormVM,
  CustomerVM
} from './CustomerSearchForm';
import { useSaveCustomer } from './useSaveCustomer';
import { useSearchCustomerUsers } from './useSearchCustomerUsers';

type CustomerPanelProps = {
  country: string;
  onChange: (customerId: string) => void;
  customerEmail?: string;
};

export const CustomerPanel = ({
  country,
  onChange,
  customerEmail
}: CustomerPanelProps) => {
  const [foundCustomer, setFoundCustomer] = useState<CustomerVM | undefined>();
  const [saveCustomer, saveCustomerLoading] = useSaveCustomer(
    foundCustomer?.id
  );
  const handleCustomerFormSubmit = async ({
    email,
    firstName,
    lastName,
    locale,
    phone
  }: CustomerFormVM) => {
    const customerFields = {
      email,
      firstName,
      lastName,
      locale,
      phone
    };
    const customerId = await saveCustomer(customerFields);
    onChange(customerId);
  };

  const [searchCustomerUsers, searchCustomerUsersQuery] =
    useSearchCustomerUsers();

  const [customerFormDefaultValues, setCustomerFormDefaultValues] =
    useState<DefaultValues<CustomerFormVM>>();

  const handleUserSearch = useCallback(
    async ({ email }: CustomerSearchFormVM) => {
      const customer = await searchCustomerUsers(email);
      const foundCustomer = customer ?? {
        email
      };

      setFoundCustomer(foundCustomer);
      const userLocale =
        typeof foundCustomer?.locale === 'string'
          ? toValidLocale(foundCustomer.locale)
          : foundCustomer?.locale;

      const locale =
        getCountry(userLocale) === country
          ? userLocale
          : getDefaultLocaleOfCountry(country);
      if (foundCustomer.id) {
        onChange(foundCustomer.id);
      }
      setCustomerFormDefaultValues({ ...foundCustomer, locale });
    },
    [country, onChange, searchCustomerUsers]
  );

  useEffect(() => {
    if (country) {
      const locale = getDefaultLocaleOfCountry(country);
      setCustomerFormDefaultValues({ ...foundCustomer, locale });
    }
  }, [country, foundCustomer]);

  useEffect(() => {
    if (customerEmail) {
      handleUserSearch({ email: customerEmail });
    }
  }, [customerEmail, handleUserSearch]);

  const [customerSearchFormDefaultValues, setCustomerSearchFormDefaultValues] =
    useState(() => ({
      email: customerEmail
    }));

  return foundCustomer ? (
    <CustomerForm
      isNewCustomer={!foundCustomer.id}
      onSubmit={handleCustomerFormSubmit}
      loading={saveCustomerLoading}
      defaultValues={customerFormDefaultValues}
      country={country}
      onBack={() => {
        setCustomerSearchFormDefaultValues({ email: foundCustomer.email });
        setFoundCustomer(null);
        setCustomerFormDefaultValues(null);
      }}
    />
  ) : (
    <CustomerSearchForm
      country={country}
      defaultValues={customerSearchFormDefaultValues}
      loading={searchCustomerUsersQuery.loading}
      onSearch={handleUserSearch}
    />
  );
};
