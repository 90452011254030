import { OrderOverviewColumnName } from '../constants';

interface ColumnNamePermissionCheckerProps {
  isAllowedOrderSalesAgentAssignees?: boolean;
  isEnabledSalesAgentsIrrelevantColums?: boolean;
  isCustomerLeadAssignable?: boolean;
}

export const COLUMN_NAME_TO_PERMISSION_CHECKER: Record<
  string,
  (x: ColumnNamePermissionCheckerProps) => boolean
> = {
  [OrderOverviewColumnName.OverdueMaxEta]: ({
    isEnabledSalesAgentsIrrelevantColums,
    isCustomerLeadAssignable
  }) => !(isEnabledSalesAgentsIrrelevantColums && isCustomerLeadAssignable),
  [OrderOverviewColumnName.OverdueBranchDelivery]: ({
    isEnabledSalesAgentsIrrelevantColums,
    isCustomerLeadAssignable
  }) => !(isEnabledSalesAgentsIrrelevantColums && isCustomerLeadAssignable),
  [OrderOverviewColumnName.SalesAgentAssignedTo]: ({
    isAllowedOrderSalesAgentAssignees
  }) => isAllowedOrderSalesAgentAssignees
};
