import { Button, Col, Form, Row, Tooltip } from 'antd';
import { DefaultValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SearchCustomerUsersQuery } from '~/apollo/gql-types';
import { InputControlled } from '~/components/Form/InputControlled';
import { emailValidation } from '~/utils/validation/email';

export type CustomerVM =
  SearchCustomerUsersQuery['searchCustomerUsers']['entities'][number];

export type CustomerSearchFormVM = { email: string };

type CustomerSearchFormProps = {
  country: string;
  onSearch: (values: CustomerSearchFormVM) => unknown;
  loading: boolean;
  defaultValues: DefaultValues<CustomerSearchFormVM>;
};

export const CustomerSearchForm = ({
  country,
  onSearch,
  loading,
  defaultValues
}: CustomerSearchFormProps) => {
  const form = useForm<CustomerSearchFormVM>({ defaultValues });
  const { control, handleSubmit } = form;

  const handleSearch = handleSubmit(onSearch);

  const { t } = useTranslation();

  return (
    <Form
      onFinish={handleSearch}
      data-qa-selector="AHUserSearchForm"
      noValidate
    >
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <InputControlled
            required
            size="large"
            placeholder={t('bo.orderCreate.searchAutoHeroUserByEmail')}
            type="email"
            autoFocus
            autoComplete=""
            controllerProps={{
              name: 'email',
              control,
              rules: {
                required: true,
                validate: emailValidation(t)
              }
            }}
          />
        </Col>
        <Col>
          <Tooltip
            title={country ? '' : t('bo.orderCreate.selectCountry')}
            placement="bottom"
          >
            <Button
              htmlType="submit"
              size="large"
              type="primary"
              loading={loading}
              disabled={!country}
            >
              {t('bo.orderCreate.search')}
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </Form>
  );
};
