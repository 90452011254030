import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useOrderCancelTypesCallback() {
  const { t } = useTranslation('', {
    keyPrefix: 'bo.ordersPage.cancelOrder.modal.orderCancelTypes'
  });

  return useCallback(
    (value) => (value ? t(value.replace(/\-/g, '_').toUpperCase()) : null),
    [t]
  );
}
