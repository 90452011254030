import * as FINANCING_TYPES from '@retail/order-financing/src/constants/financingTypes/enum';
import { useMemo } from 'react';

import { replaceAndToUpperCase } from '~/utils/replaceAndToUpperCase';

export function useFinancingTypeOptions() {
  return useMemo(
    () =>
      Object.values(FINANCING_TYPES).map((value) => ({
        value,
        label: replaceAndToUpperCase(value)
      })),
    []
  );
}
