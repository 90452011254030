// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fH2yV_vahc0fyOQF1yep {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  /**\n   * @todo remove this after migration Verification tab to MFE\n   * @see https://wkdauto.atlassian.net/browse/NFS-1488\n   */\n}\n.fH2yV_vahc0fyOQF1yep .antd-4-21-7-card-head-title,\n.fH2yV_vahc0fyOQF1yep .antd-4-21-7-table-cell {\n  font-size: 15px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/features/VerificationTabContent/styles.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EADA;;;IAGE;AACJ;AAKM;;EAEE,0BAAA;AAHR","sourcesContent":["@import '../../styles/constants.less';\n\n.section {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  /**\n   * @todo remove this after migration Verification tab to MFE\n   * @see https://wkdauto.atlassian.net/browse/NFS-1488\n   */\n  :global {\n    .@{antd-version} {\n      &-card-head-title,\n      &-table-cell {\n        font-size: 15px !important;\n      }\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "fH2yV_vahc0fyOQF1yep"
};
export default ___CSS_LOADER_EXPORT___;
