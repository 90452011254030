import { Control, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SwitchControlled } from '~/components/Form/SwitchControlled';
import { withPermissions } from '~/components/PermissionChecker';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { useUser } from '~/hooks/useUser';

import { OrderOverviewSearchModel } from '../types';

import { FieldCol } from './FieldCol';

interface Props {
  control: Control<OrderOverviewSearchModel>;
  refetch: VoidFunction;
  setValue: UseFormSetValue<OrderOverviewSearchModel>;
}

export const OnlyShowMy = withPermissions<Props>(
  PERMISSION.CUSTOMER_LEAD_ASSIGNABLE,
  DOMAIN.CLM_GRANT
)(({ control, refetch, setValue }) => {
  const { t } = useTranslation();
  const { id } = useUser();

  function onChange(value: boolean) {
    if (value) {
      setValue('salesAgentAssignedTo', [id]);
    } else {
      setValue('salesAgentAssignedTo', []);
      setValue('isOnlyShowMy', null);
    }

    refetch();
  }

  return (
    <FieldCol>
      <SwitchControlled
        label={t('bo.orderOverview.searchForm.field.isOnlyShowMy')}
        onChange={onChange}
        controllerProps={{
          name: 'isOnlyShowMy',
          control
        }}
      />
    </FieldCol>
  );
});
