import { SelectControlled } from '@retail/backoffice-ui';
import { useHref } from '@retail/backoffice-urls';
import { ORDER_TYPES } from '@retail/order-constants/src/types';
import { Form, Modal } from 'antd';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  useSearchAdLazyQuery,
  useSearchRetailAdLazyQuery
} from '~/apollo/gql-types';
import { InputControlled } from '~/components/Form/InputControlled';
import { RetailAdState } from '~/constants/retailAdState';
import { OrderCreateFlowParams } from '~/constants/searchParams';

import { AdDetails } from './AdDetails';
import { StockNumberSearch } from './StockNumberSearch';
import { FormValues } from './types';
import { useCreationOrderTypeOptions } from './useCreationOrderTypeOptions';
import { useOrderReferenceOptions } from './useOrderReferenceOptions';
import { useAdditionalOrderCreationReasonOptions } from './useAdditionalOrderCreationReasonOptions';

export const isRetailAdInvalid = <T extends { state?: string }>(ad?: T) =>
  (
    [RetailAdState.Reserved, RetailAdState.DeliveredToCustomer] as string[]
  ).includes(ad?.state);

interface OrderTypeModalProps {
  onClose: () => void;
}

export const OrderTypeModal = ({ onClose }: OrderTypeModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const createOrderUrl = useHref('CREATE_ORDER');
  const orderTypeOptions = useCreationOrderTypeOptions();

  const formInstance = useForm<FormValues>({
    defaultValues: {
      orderType:
        orderTypeOptions.length === 1 ? orderTypeOptions[0].value : undefined
    }
  });
  const { control } = formInstance;
  const formValues = {
    orderType: useWatch({ name: 'orderType', control }),
    customerEmail: useWatch({ name: 'customerEmail', control }),
    stockNumber: useWatch({ name: 'stockNumber', control })
  };

  const referenceOrders = useOrderReferenceOptions();

  const [searchAd, { data: adSearchResult, loading: adSearchLoading }] =
    useSearchAdLazyQuery();

  const [
    searchRetailAd,
    { data: retailAdSearchResult, loading: retailAdSearchLoading }
  ] = useSearchRetailAdLazyQuery();

  const handleVehicleSearch = async (stockNumber: string) => {
    const { data } = await searchAd({ variables: { stockNumber } });
    if (data?.ad) {
      const { data: retailAdResponse } = await searchRetailAd({
        variables: { adId: data.ad.id }
      });

      const ad = {
        id: data.ad.id,
        isReserved: isRetailAdInvalid(retailAdResponse?.retailAd)
      };
      return ad;
    }
  };

  const handleSubmit = formInstance.handleSubmit(
    ({ orderType, reason, orderReferenceId, stockNumber }) => {
      const params =
        orderType === ORDER_TYPES.STANDARD
          ? {
              [OrderCreateFlowParams.OrderType]: orderType,
              [OrderCreateFlowParams.StockNumber]: stockNumber
            }
          : orderType === ORDER_TYPES.ADDITIONAL
          ? {
              [OrderCreateFlowParams.OrderType]: orderType,
              [OrderCreateFlowParams.Reason]: reason,
              [OrderCreateFlowParams.LinkedOrderId]: orderReferenceId
            }
          : { [OrderCreateFlowParams.OrderType]: orderType };

      navigate({
        pathname: createOrderUrl,
        search: new URLSearchParams(params).toString()
      });
    }
  );

  const reasonOptions = useAdditionalOrderCreationReasonOptions();

  const submitButtonDisabled =
    formValues.orderType === ORDER_TYPES.STANDARD &&
    (adSearchResult?.ad?.vehicle?.stockNumber !== formValues.stockNumber ||
      isRetailAdInvalid(retailAdSearchResult?.retailAd) ||
      retailAdSearchLoading);

  return (
    <Modal
      visible
      width={700}
      data-qa-selector="orderTypeModal"
      okText={t('bo.orderOverview.orderTypeForm.confirm')}
      onCancel={onClose}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: submitButtonDisabled,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'data-qa-selector': 'submit-button'
      }}
      cancelButtonProps={{
        disabled: submitButtonDisabled,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'data-qa-selector': 'cancel-button'
      }}
    >
      <FormProvider {...formInstance}>
        <Form layout="vertical">
          <SelectControlled
            label={t('bo.orderOverview.orderTypeForm.orderType.label')}
            qaSelector="orderTypeSelect"
            required
            controllerProps={{
              control,
              name: 'orderType',
              rules: { required: true }
            }}
            options={orderTypeOptions}
          />
          {formValues.orderType === ORDER_TYPES.STANDARD && (
            <>
              <StockNumberSearch
                form={formInstance}
                isLoading={adSearchLoading || retailAdSearchLoading}
                onSearch={handleVehicleSearch}
              />
              {adSearchResult &&
                retailAdSearchResult?.retailAd &&
                !isRetailAdInvalid(retailAdSearchResult.retailAd) && (
                  <AdDetails ad={adSearchResult.ad} />
                )}
            </>
          )}
          {formValues.orderType === ORDER_TYPES.ADDITIONAL && (
            <>
              <SelectControlled
                required
                label={t('bo.orderOverview.orderTypeForm.reason.label')}
                qaSelector="reasonField"
                controllerProps={{
                  control,
                  name: 'reason',
                  rules: { required: true }
                }}
                options={reasonOptions}
              />
              <InputControlled
                required
                type="email"
                qaSelector="emailField"
                label={t('bo.orderOverview.orderTypeForm.customerEmail.label')}
                controllerProps={{
                  control,
                  name: 'customerEmail',
                  rules: { required: true }
                }}
                onBlur={(e) => {
                  const email = e.target.value.trim();
                  if (email.length > 0) {
                    referenceOrders.doFetch(email);
                  }
                }}
                onChange={() => formInstance.setValue('orderReferenceId', null)}
              />
              <SelectControlled
                required
                label={t(
                  'bo.orderOverview.orderTypeForm.referenceOrderNumber.label'
                )}
                qaSelector="orderNumberReference"
                controllerProps={{
                  control,
                  name: 'orderReferenceId',
                  rules: { required: true }
                }}
                options={referenceOrders.data}
                loading={referenceOrders.isLoading}
                disabled={!formValues.customerEmail}
              />
            </>
          )}
        </Form>
      </FormProvider>
    </Modal>
  );
};
