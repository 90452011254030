import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@retail/backoffice-ui';

import { AdEntity } from './types';

interface AdDetailsProps {
  ad: AdEntity;
}

const { Item } = Descriptions;

export const AdDetails = ({ ad }: AdDetailsProps) => {
  const { t } = useTranslation();
  return (
    <Descriptions layout="vertical" bordered column={4}>
      <Item label={t('bo.orderOverview.orderTypeForm.vehicle.vin')}>
        {ad.vehicle?.vin}
      </Item>
      <Item label={t('bo.orderOverview.orderTypeForm.vehicle.country')}>
        {ad.countryCode}
      </Item>
      <Item label={t('bo.orderOverview.orderTypeForm.vehicle.title')}>
        {ad.title} {ad.subtitle}
      </Item>
      <Item label={t('bo.orderOverview.orderTypeForm.vehicle.price')}>
        {formatCurrency(ad.price)}
      </Item>
    </Descriptions>
  );
};
