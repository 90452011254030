import { formatCurrency } from '@retail/backoffice-ui';
import { useHref } from '@retail/backoffice-urls';
import { SortType } from '@retail/gql-utils';
import { ControllerModel, useSearchParamsController } from '@retail/hooks';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useEsSearchOrdersQuery } from '~/apollo/gql-types';
import { SortOrder } from '~/constants/SortOrder';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';
import { useEnabledFullName } from '~/hooks/useEnabledFullName';
import { EsOrderModel } from '~/types/EsOrderModel';
import { formatDateTime } from '~/utils/date';

import { formToInput } from '../mappers/formToInput';
import { OrderOverviewSearchModel } from '../types';

import { DELIVERY_OPTION_TRANSLATION_KEY } from './useDeliveryOptions';
import { useOrderCancelTypesCallback } from './useOrderCancelTypes';

/**
 * @description
 * In order to have no issues with {@link reset} from {@link react-hook-form}
 * we have to have default values for all fields.
 * {@link reset} - only support non undefined value.
 */
export const DEFAULT_VALUES: Omit<
  Required<OrderOverviewSearchModel>,
  keyof ControllerModel
> &
  Pick<OrderOverviewSearchModel, keyof ControllerModel> = {
  sort: 'createdAt',
  direction: SortType.DESC,
  retailCountry: [],
  state: [],
  paymentStatus: [],
  paymentType: [],
  financeFlowType: [],
  financingType: [],
  financeApplicationStatus: [],
  deliveryOption: [],
  orderNumber: null,
  stockNumber: null,
  licensePlate: null,
  customerEmail: null,
  customerLastName: null,
  registrationDocumentsReceived: null,
  carRetailReady: null,
  exitCheckState: null,
  overdueBranchDelivery: null,
  handoverDateSet: null,
  retailDeliveryETAAvailable: null,
  carInBranch: null,
  documentRequests: null,
  branchCheckupSuccessful: null,
  overdueMaxEta: null,
  tradeIn: null,
  flowVersion: null,
  createdAt: [],
  contractSignedOn: [],
  pendingVerificationOn: [],
  completedOn: [],
  deliveredOn: [],
  verifiedOn: [],
  salesAgentAssignedTo: [],
  isOnlyShowMy: null,
  isOnlyShowActive: null,
  cancellationRequested: null,
  canceledOn: [],
  opportunityId: null,
  type: [],
  conversionType: [],
  customerType: [],
  daysInInventory: [null, null],
  warranty: [],
  secondaryWheelsAdded: null
};

export function useDataSource() {
  const ordersHref = useHref('ORDERS');
  const { t } = useTranslation();
  const translateOrderCancelTypes = useOrderCancelTypesCallback();
  const getEnabledFullName = useEnabledFullName();
  const {
    controller,
    formValues,
    onPageChange,
    updateController,
    clearController
  } = useSearchParamsController<OrderOverviewSearchModel>(
    ({ queryString, navigate }) => navigate(`${ordersHref}${queryString}`),
    DEFAULT_VALUES
  );
  const { data, loading, refetch } = useEsSearchOrdersQuery({
    ...REQUEST_BASE_OPTIONS,
    notifyOnNetworkStatusChange: true,
    variables: {
      search: formToInput(controller as OrderOverviewSearchModel)
    },
    onCompleted(data) {
      updateController({
        totalPages: data?.esSearchOrders.totalEntityCount
      } as OrderOverviewSearchModel);
    }
  });
  const entities = data?.esSearchOrders?.entities;

  const dataSource = useMemo(
    () =>
      entities?.map(
        (x): EsOrderModel => ({
          ...x,
          createdAt: formatDateTime(x.createdAt),
          contractSignedOn: formatDateTime(x.contractSignedOn),
          deliveredOn: formatDateTime(x.deliveredOn),
          verifiedOn: formatDateTime(x.verifiedOn),
          canceledOn: formatDateTime(x.canceledOn),
          pendingVerificationOn: formatDateTime(x.pendingVerificationOn),
          totalPriceGross: formatCurrency(x.totalPriceGross),
          salesAgentAssignedTo: getEnabledFullName(x.salesAgentAssignedTo),
          cancelType: translateOrderCancelTypes(x.cancelType),
          cancelPeriod: translateOrderCancelTypes(x.cancelPeriod),
          cancelReason: translateOrderCancelTypes(x.cancelReason),
          subCancelReason: translateOrderCancelTypes(x.subCancelReason),
          deliveryOption: x.deliveryOption
            ? t(`${DELIVERY_OPTION_TRANSLATION_KEY}${x.deliveryOption}`)
            : null
        })
      ),
    [entities, getEnabledFullName, t, translateOrderCancelTypes]
  );

  const onChange = useCallback(
    (
      pagination,
      filters,
      sorter: SorterResult<EsOrderModel>,
      extra: TableCurrentDataSource<EsOrderModel>
    ) => {
      const { action } = extra;
      const { order } = sorter;
      const field =
        sorter.field === 'state' ? 'bo_standard_order_state' : sorter.field;

      if (action === 'sort' && order) {
        updateController({
          page: 1,
          sort: field,
          direction: order === SortOrder.Ascend ? SortType.ASC : SortType.DESC
        } as OrderOverviewSearchModel);
      } else {
        updateController({
          page: 1,
          sort: undefined,
          direction: undefined
        } as OrderOverviewSearchModel);
      }
    },
    [updateController]
  );

  return {
    dataSource,
    controller,
    isLoading: loading,
    formValues,
    refetch,
    onPageChange,
    updateController,
    clearController,
    onChange
  };
}
