import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UserProjection } from '~/apollo/gql-types';
import { getFullName } from '~/utils/user/getFullName';

export function useEnabledFullName() {
  const { t } = useTranslation();

  return useCallback(
    (
      user: Partial<
        Pick<UserProjection, 'firstName' | 'lastName' | 'enabled'>
      > = {}
    ) => {
      const fullName = getFullName(user);

      if (typeof user?.enabled === 'boolean') {
        return `${fullName} ${
          user.enabled ? '' : t('bo.taskManagement.search.label.inactive')
        }`.trim();
      }

      return fullName;
    },
    [t]
  );
}
