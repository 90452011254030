import { TableCell } from '@retail/backoffice-ui';
import { Card, CheckboxProps, Table, TableProps } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTradeInUpsellOptionsQuery } from '~/apollo/gql-types';

import { ActionButton } from './ActionButton';
import { RetailTradeInUpSellOptionModel } from './types';
import { useColumns } from './useColumns';
import { withFFUpsellTradeInBoShow } from './withFFUpsellTradeInBoShow';

const DEFAULT_VALUE: RetailTradeInUpSellOptionModel[] = [];

export interface TradeInUpsellProps {
  orderId: string;
  country: string;
}

export const TradeInUpsell = withFFUpsellTradeInBoShow<TradeInUpsellProps>(
  ({ orderId }) => {
    const { t } = useTranslation();
    const [selectedRow, setSelectedRow] =
      useState<RetailTradeInUpSellOptionModel>();
    const { data, loading, called } = useTradeInUpsellOptionsQuery({
      variables: { orderId }
    });
    const columns = useColumns();
    const rowSelection: TableProps<RetailTradeInUpSellOptionModel>['rowSelection'] =
      {
        type: 'radio',
        onChange: (_: React.Key[], selectedRows) => {
          const [selectedRow] = selectedRows;
          setSelectedRow(selectedRow);
        },
        columnTitle: (
          <strong>
            {t('bo.summaryVerificationTradeInUpsell.table.column.action')}
          </strong>
        ),
        renderCell(_value, { active, stockNumber }, _index, originNode) {
          return active ? (
            originNode
          ) : (
            <TableCell
              data-qa-selector="inactiveTradeIn"
              data-qa-selector-value={stockNumber}
            />
          );
        },
        getCheckboxProps: ({ stockNumber }) =>
          ({
            ['data-qa-selector']: 'tradeInRadio',
            ['data-qa-selector-value']: stockNumber
          } as CheckboxProps)
      };

    const { activeTradeIns = DEFAULT_VALUE, inactiveTradeIns = DEFAULT_VALUE } =
      data?.tradeInUpsellOptions ?? {};

    const dataSource = useMemo(
      () =>
        activeTradeIns
          .map((x): RetailTradeInUpSellOptionModel => ({ ...x, active: true }))
          .concat(...inactiveTradeIns),
      [activeTradeIns, inactiveTradeIns]
    );

    return called && !loading && !dataSource.length ? null : (
      <Card
        size="small"
        title={t('bo.summaryVerificationTradeInUpsell.table.title')}
        extra={<ActionButton orderId={orderId} tradeInId={selectedRow?.id} />}
      >
        <Table
          size="small"
          rowKey="id"
          scroll={{ x: 0 }}
          pagination={false}
          dataSource={dataSource}
          loading={loading}
          rowSelection={rowSelection}
          columns={columns}
        />
      </Card>
    );
  }
);
