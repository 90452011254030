import { LockFilled } from '@ant-design/icons';
import { NoValue } from '@retail/backoffice-ui';
import * as CustomerTypes from '@retail/order-constants/src/customerTypes/enum';
import { Tooltip } from 'antd';

import { ElasticCustomerProjection } from '~/apollo/gql-types';
import { getFullName } from '~/utils/user/getFullName';

interface Props {
  value: ElasticCustomerProjection;
}

export function RetailCustomer({ value }: Props) {
  if (!value) {
    return <NoValue />;
  }

  const { name, customerType } = value;
  const fullName = getFullName(value);
  const isPrivateCustomer = customerType === CustomerTypes.PRIVATE;

  if (isPrivateCustomer) {
    return <>{fullName || <NoValue />}</>;
  }

  if (!isPrivateCustomer && !name) {
    return <NoValue />;
  }

  return (
    <Tooltip placement="left" title={customerType}>
      <LockFilled /> {name}
    </Tooltip>
  );
}
