import { OptionModel } from '@retail/backoffice-ui';
import { COUNTRIES_VALUES } from '@retail/i18n/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '~/hooks/useUser';

const sorter = (a: OptionModel, b: OptionModel) =>
  a.label?.toString?.().localeCompare(b.label?.toString?.());

const KEY = 'country';

export function useAccessibleCountryOptions() {
  const { accessParameters } = useUser();
  const { t } = useTranslation();

  return useMemo(() => {
    if (
      accessParameters.some(({ key, value }) => key === KEY && value === '*')
    ) {
      return COUNTRIES_VALUES.map(
        (value: string): OptionModel => ({
          value,
          label: t(`bo.common.country.${value}`)
        })
      ).sort(sorter);
    }

    return accessParameters
      .filter(
        ({ key, value }) => key === KEY && COUNTRIES_VALUES.includes(value)
      )
      .map(
        ({ value }): OptionModel => ({
          value,
          label: t(`bo.common.country.${value}`)
        })
      )
      .sort(sorter);
  }, [accessParameters, t]);
}
