import { Card, CheckboxProps, Table, TableProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RetailWarrantyProjection,
  useWarrantyUpsellOptionsQuery
} from '~/apollo/gql-types';

import { ActionButton } from './ActionButton';
import { useColumns } from './useColumns';
import { withFFUpsellWarrantyBoShow } from './withFFUpsellWarrantyBoShow';

export interface WarrantyUpsellProps {
  orderId: string;
  country: string;
}

export const WarrantyUpsell = withFFUpsellWarrantyBoShow<WarrantyUpsellProps>(
  ({ orderId }) => {
    const { t } = useTranslation();
    const [selectedRow, setSelectedRow] = useState<RetailWarrantyProjection>();
    const { data, loading, called } = useWarrantyUpsellOptionsQuery({
      variables: { orderId },
      onCompleted() {
        setSelectedRow(undefined);
      }
    });
    const columns = useColumns();
    const rowSelection: TableProps<RetailWarrantyProjection>['rowSelection'] = {
      type: 'radio',
      onChange: (_: React.Key[], selectedRows) => {
        const [selectedRow] = selectedRows;
        setSelectedRow(selectedRow);
      },
      columnTitle: (
        <strong>
          {t('bo.summaryVerificationWarrantyUpsell.table.column.action')}
        </strong>
      ),
      getCheckboxProps: ({ internalReferenceName }) =>
        ({
          ['data-qa-selector']: 'warrantyUpsellRadio',
          ['data-qa-selector-value']: internalReferenceName
        } as CheckboxProps)
    };

    if (called && !loading && !data?.warrantyUpsellOptions?.length) {
      return null;
    }

    return (
      <Card
        size="small"
        title={t('bo.summaryVerificationWarrantyUpsell.table.title')}
        extra={<ActionButton orderId={orderId} warrantyId={selectedRow?.id} />}
      >
        <Table
          size="small"
          rowKey="id"
          scroll={{ x: 0 }}
          columns={columns}
          rowSelection={rowSelection}
          dataSource={data?.warrantyUpsellOptions}
          pagination={false}
          loading={loading}
        />
      </Card>
    );
  }
);
