import { LinkButton, useHref } from '@retail/backoffice-urls';
import { useTranslation } from 'react-i18next';

import { PermissionChecker } from '~/components/PermissionChecker';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

export function GoToOrderListButton() {
  const orderListHref = useHref('ORDERS');
  const { t } = useTranslation();

  return (
    <PermissionChecker
      allow={PERMISSION.QUERY_SEARCH_ORDERS}
      domain={DOMAIN.ORDER_MANAGEMENT}
    >
      <LinkButton to={orderListHref} type="primary" size="large">
        {t('bo.orderCreate.goToList')}
      </LinkButton>
    </PermissionChecker>
  );
}
