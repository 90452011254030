import { Card, notification, Space, Steps } from 'antd';
import { useMemo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { ApolloError } from '@apollo/client/errors';
// @retail
import { Layout, LayoutBody, LayoutHeader } from '@retail/backoffice-ui';
import { PATHS, useHref } from '@retail/backoffice-urls';
import { ORDER_TYPES, CHECKOUT_STEPS } from '@retail/order-constants';
import { useOpenCheckoutInAdminMode } from '@retail/bo-open-autohero';

import {
  CheckoutSource,
  RetailCheckoutCreateProjectionInput,
  useCreateRetailCheckoutMutation
} from '~/apollo/gql-types';
import { FEATURE } from '~/constants/feature';
import { OrderCreateFlowParams } from '~/constants/searchParams';
import { useFeatures } from '~/hooks/useFeatures';
import { isOrderType } from '~/types/OrderType';

import { CreationDetails } from './CreationDetails';
import { CustomerPanel } from './CustomerPanel';
import { GoToOrderListButton } from './GoToOrderListButton';
import { isSearchParamsValid } from './isSearchParamsValid';
import { SelectRetailCountryForm } from './SelectRetailCountryForm';
import { useCreateOrderByType } from './useCreateOrderByType';
import { useOrderById } from './useOrderById';
import { useRetailAdInfo } from './useRetailAdInfo';

export const OrderCreate = () => {
  const { t } = useTranslation();
  const [{ retailCountry, customerId, customerEmail }, setOrderCreateValues] =
    useState({
      retailCountry: '',
      customerId: '',
      customerEmail: ''
    });

  const { language } = useParams();
  const [searchParams] = useSearchParams();
  const orderTypeSearchParam = searchParams.get(
    OrderCreateFlowParams.OrderType
  );
  const orderType = useMemo(
    () =>
      isOrderType(orderTypeSearchParam)
        ? orderTypeSearchParam
        : ORDER_TYPES.STANDARD,
    [orderTypeSearchParam]
  );

  const ordersOverviewHref = useHref('ORDERS');

  const navigate = useNavigate();
  useEffect(() => {
    if (!isSearchParamsValid(searchParams)) {
      const params = new URLSearchParams({
        [OrderCreateFlowParams.OpenCreateModal]: ''
      });
      navigate(`${ordersOverviewHref}?${params.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isFeatureEnabled } = useFeatures({
    names: [
      FEATURE.CAR_IN_SHOWROOOM_AUTOHERO,
      FEATURE.CREATE_BO_ORDER_USING_AH_CHECKOUT
    ],
    country: retailCountry
  });

  const linkedOrderId = searchParams.get(OrderCreateFlowParams.LinkedOrderId);
  const { doFetch: fetchOrderById } = useOrderById();
  useEffect(() => {
    if (linkedOrderId) {
      fetchOrderById(linkedOrderId).then((order) => {
        if (order) {
          setOrderCreateValues((prev) => ({
            ...prev,
            customerEmail: order.customerEmail,
            retailCountry: order.retailCountry
          }));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedOrderId]);

  const saveCountry = (country: string) =>
    setOrderCreateValues((prev) => ({
      ...prev,
      retailCountry: country
    }));

  const handleCustomerChange = useCallback(
    (customerId) => setOrderCreateValues((prev) => ({ ...prev, customerId })),
    []
  );

  const onError = useCallback(
    (error: ApolloError) => {
      notification.error({
        message: error.message ?? t('bo.orderCreate.submitError')
      });
    },
    [t]
  );

  const stockNumber = searchParams.get(OrderCreateFlowParams.StockNumber);
  const retailAdInfo = useRetailAdInfo({ stockNumber });

  const [createOrderByType, createOrderLoading] = useCreateOrderByType({
    retailAdInfo,
    onError
  });
  const createOrder = useCallback(
    async (creationDetails: {
      orderVersion: string;
      conversionType: string;
      checkoutBranchId: number;
      isAhCheckoutByAgent?: boolean;
    }) =>
      createOrderByType({
        type: orderType,
        retailCountry,
        userId: customerId,
        linkedOrderId,
        linkedOrderReason: searchParams.get(OrderCreateFlowParams.Reason),
        ...creationDetails
      }),
    [
      orderType,
      retailCountry,
      customerId,
      linkedOrderId,
      searchParams,
      createOrderByType
    ]
  );

  const [createRetailCheckout, { loading: createRetailCheckoutLoading }] =
    useCreateRetailCheckoutMutation({ onError });
  const createCheckout = useCallback(
    (checkout: RetailCheckoutCreateProjectionInput) =>
      createRetailCheckout({
        variables: {
          checkout: { ...checkout, source: CheckoutSource.Backoffice }
        }
      }),
    [createRetailCheckout]
  );

  const handleCreateOrder = useCallback(
    async (values) => {
      const orderId = await createOrder(values);

      if (orderId) {
        await createCheckout({
          orderId,
          completedSteps: [CHECKOUT_STEPS.TRADE_IN]
        });
        navigate(generatePath(PATHS.EDIT_ORDER, { language, orderId }));
      }
    },
    [language, createOrder, createCheckout, navigate]
  );

  const [{ loading: opening }, openCheckoutInAdminMode] =
    useOpenCheckoutInAdminMode({
      country: retailCountry,
      adId: retailAdInfo?.adId,
      userId: customerId
    });

  const handleCreateOrderFromAhCheckout = useCallback(
    async (values) => {
      const orderId = await createOrder({
        ...values,
        isAhCheckoutByAgent: true
      });

      if (orderId) {
        await createCheckout({ orderId, completedSteps: [] });
        try {
          await openCheckoutInAdminMode();
          navigate(generatePath(PATHS.ORDER, { language, orderId }));
        } catch {
          navigate(generatePath(PATHS.EDIT_ORDER, { language, orderId }));
        }
      }
    },
    [createOrder, createCheckout, openCheckoutInAdminMode, navigate, language]
  );

  return (
    <Layout>
      <LayoutHeader title={t('bo.orderCreate.newOrder')}>
        <GoToOrderListButton />
      </LayoutHeader>
      <LayoutBody>
        <Steps type="navigation" current={0}>
          <Steps.Step active title={t('bo.orderCreate.countryAndUser')} />
          <Steps.Step disabled title={t('bo.orderCreate.customerDetails')} />
          <Steps.Step disabled title={t('bo.orderCreate.orderDetails')} />
          <Steps.Step disabled title={t('bo.orderCreate.addresses')} />
          <Steps.Step disabled title={t('bo.orderCreate.confirmation')} />
        </Steps>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card
            title={t('bo.orderCreate.retailDetails')}
            data-qa-selector="retailDetailsPanel"
          >
            <SelectRetailCountryForm
              onChange={saveCountry}
              country={retailCountry}
            />
          </Card>
          <Card
            title={t('bo.orderCreate.autoHeroUser')}
            data-qa-selector="AHUserPanel"
          >
            <CustomerPanel
              customerEmail={customerEmail}
              country={retailCountry}
              onChange={handleCustomerChange}
            />
          </Card>
          <Card
            title={t('bo.orderCreate.creationDetails.panelTitle')}
            data-qa-selector="creationDetailsPanel"
          >
            <CreationDetails
              orderType={orderType}
              retailCountry={retailCountry}
              isCarInShowroomEnabled={isFeatureEnabled(
                FEATURE.CAR_IN_SHOWROOOM_AUTOHERO
              )}
              isCreateBoOrderUsingAhCheckoutEnabled={isFeatureEnabled(
                FEATURE.CREATE_BO_ORDER_USING_AH_CHECKOUT
              )}
              isSubmitDisabled={!retailCountry || !customerId}
              isSubmitLoading={
                createOrderLoading || createRetailCheckoutLoading || opening
              }
              onSubmit={handleCreateOrder}
              handleCreateOrderFromAhCheckout={handleCreateOrderFromAhCheckout}
            />
          </Card>
        </Space>
      </LayoutBody>
    </Layout>
  );
};
