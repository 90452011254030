import { OptionModel } from '@retail/backoffice-ui';
import { CONVERSION_TYPE_VALUES_UPPERCASE } from '@retail/order-constants/src/conversionTypesUppercase';
import { useTranslation } from 'react-i18next';

export const CONVENTION_TYPE_KEY_PREFIX =
  'bo.orderOverview.searchForm.options.conversionTypes';

export function useConversionTypeOptions() {
  const { t } = useTranslation('', { keyPrefix: CONVENTION_TYPE_KEY_PREFIX });

  return CONVERSION_TYPE_VALUES_UPPERCASE.map(
    (value): OptionModel => ({ value, label: t(value) })
  );
}
