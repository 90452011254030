import { useDevAuthToken } from '@retail/dev-auth';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './i18n';
import { RootProvider } from './providers/RootProvider';
import { ROUTES } from './routes';

export default function Root() {
  useDevAuthToken();

  return (
    <div data-qa-selector="order-management-root">
      <RootProvider>
        <BrowserRouter>
          <Routes>
            {ROUTES.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
          </Routes>
        </BrowserRouter>
      </RootProvider>
    </div>
  );
}
