import {
  createFilter,
  createFilterPayload,
  FilterType
} from '@retail/gql-utils';
import { useCallback } from 'react';

import { useSearchCustomerUsersLazyQuery } from '~/apollo/gql-types';
import { REQUEST_BASE_OPTIONS } from '~/constants/api';

interface Props {
  email: string;
}

const getUserSearchRequestBody = ({ email }: Props) => {
  const filter = createFilter(null, FilterType.AND, [
    createFilter('email', FilterType.EQUAL, email?.toLowerCase?.()),
    createFilter('enabled', FilterType.EQUAL, true)
  ]);

  return createFilterPayload({
    filter,
    pageSize: 1
  });
};

export const useSearchCustomerUsers = () => {
  const [searchCustomers, searchCustomerUsersQuery] =
    useSearchCustomerUsersLazyQuery({
      ...REQUEST_BASE_OPTIONS,
      notifyOnNetworkStatusChange: true
    });

  return [
    useCallback(
      (email: string) =>
        searchCustomers({
          variables: { search: getUserSearchRequestBody({ email }) }
        }).then((res) => res.data?.searchCustomerUsers?.entities?.[0]),
      [searchCustomers]
    ),
    searchCustomerUsersQuery
  ] as const;
};
