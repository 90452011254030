import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PermissionChecker } from '~/components/PermissionChecker';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { OrderCreateFlowParams } from '~/constants/searchParams';

import { OrderTypeModal } from './OrderTypeModal';

export function CreateOrderButton() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has(OrderCreateFlowParams.OpenCreateModal)) {
      setModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PermissionChecker
        allow={PERMISSION.MUTATION_CREATE_ORDER}
        domain={DOMAIN.ORDER_MANAGEMENT}
      >
        <Button
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          data-qa-selector="createOrderButton"
          onClick={() => setModalOpen(true)}
        >
          {t('bo.orderOverview.button.createOrder')}
        </Button>
      </PermissionChecker>
      {isModalOpen && <OrderTypeModal onClose={() => setModalOpen(false)} />}
    </>
  );
}
