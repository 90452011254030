import { DELIVERY_BRANCH_TYPES } from '@retail/order-constants';

import { useCheckoutBranchesForOrderCreationQuery } from '~/apollo/gql-types';

export const useCheckoutBranchOptions = ({
  retailCountry,
  isCarInShowroomEnabled
}: {
  retailCountry: string | null;
  isCarInShowroomEnabled: boolean;
}) => {
  const { data, loading } = useCheckoutBranchesForOrderCreationQuery({
    skip: !isCarInShowroomEnabled || !retailCountry,
    variables: {
      retailCountry,
      branchTypes: [
        DELIVERY_BRANCH_TYPES.DEFAULT,
        DELIVERY_BRANCH_TYPES.RETAIL,
        DELIVERY_BRANCH_TYPES.WORKSHOP
      ],
      first: 0,
      offset: 50
    }
  });

  const branchOptions = data?.checkoutBranches?.entities?.map((it) => ({
    value: it.id,
    label: it.name
  }));

  return {
    branchOptions,
    isBranchOptionsLoading: loading
  };
};
