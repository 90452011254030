import { withRootRouteProvider } from '~/providers/RootProvider';

import {
  SecondaryWheelsUpsell,
  SecondaryWheelsUpsellProps
} from './SecondaryWheelsUpsell ';
import { TradeInUpsell, TradeInUpsellProps } from './TradeInUpsell';
import { WarrantyUpsell, WarrantyUpsellProps } from './WarrantyUpsell';
import cn from './styles.less';
export interface VerificationTabContentProps
  extends WarrantyUpsellProps,
    SecondaryWheelsUpsellProps,
    TradeInUpsellProps {}

export const VerificationTabContent =
  withRootRouteProvider<VerificationTabContentProps>(
    ({ country, orderId }: VerificationTabContentProps) => {
      return (
        <div className={cn.section}>
          <WarrantyUpsell country={country} orderId={orderId} />
          <SecondaryWheelsUpsell country={country} orderId={orderId} />
          <TradeInUpsell country={country} orderId={orderId} />
        </div>
      );
    }
  );
