import { FormItem } from '@retail/backoffice-ui';
import { ColProps, Switch } from 'antd';
import { Dispatch, ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';

interface Props<T> {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  labelCol?: ColProps;
  required?: boolean;
  disabled?: boolean;
  qaSelector?: string;
  className?: string;
  onChange?: Dispatch<boolean>;
}

export function SwitchControlled<T extends FieldValues>({
  label,
  labelCol,
  required,
  disabled,
  className,
  controllerProps,
  onChange
}: Props<T>) {
  const { field } = useController(controllerProps);

  function handleChange(nextValue: boolean) {
    field.onChange(nextValue);
    onChange?.(nextValue);
  }

  return (
    <FormItem
      label={label}
      labelCol={labelCol}
      required={required}
      disabled={disabled}
      className={className}
      controllerProps={controllerProps}
    >
      <Switch
        checked={field.value ?? false}
        {...field}
        onChange={handleChange}
      />
    </FormItem>
  );
}
