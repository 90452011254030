import { CopyOutlined } from '@ant-design/icons';
import { useHref } from '@retail/backoffice-urls';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import cn from './styles.less';

const { Text } = Typography;

interface Props {
  adId: string;
  stockNumber: string;
}

export function StockNumber({ adId, stockNumber }: Props) {
  const href = useHref('RETAIL_AD_ITEM', { adId });

  return (
    <Text
      className={cn.text}
      copyable={{
        text: stockNumber,
        icon: <CopyOutlined data-qa-selector="copyStockNumberIcon" />
      }}
    >
      <Link to={href}>{stockNumber}</Link>
    </Text>
  );
}
