import { FilterType } from '@retail/gql-utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionModel } from '~/types/OptionModel';

export function stringify(...args) {
  return JSON.stringify(args);
}

export function useOverdueOptions(firstOptionLabelKey) {
  const { t } = useTranslation();

  return useMemo(
    (): OptionModel[] => [
      {
        label: t(firstOptionLabelKey),
        value: stringify([FilterType.GREATER_THAN, 0])
      },
      {
        label:
          '> ' +
          t('bo.orderOverview.searchForm.field.ovedue.option.days', {
            value: 40
          }),
        value: stringify([FilterType.GREATER_OR_EQUAL, 40])
      },
      {
        label:
          '> ' +
          t('bo.orderOverview.searchForm.field.ovedue.option.days', {
            value: 30
          }),
        value: stringify(
          [FilterType.GREATER_OR_EQUAL, 30],
          [FilterType.LESS_THAN, 40]
        )
      },
      {
        label:
          '> ' +
          t('bo.orderOverview.searchForm.field.ovedue.option.days', {
            value: 20
          }),
        value: stringify(
          [FilterType.GREATER_OR_EQUAL, 20],
          [FilterType.LESS_THAN, 30]
        )
      },
      {
        label:
          '< ' +
          t('bo.orderOverview.searchForm.field.ovedue.option.days', {
            value: 20
          }),
        value: stringify(
          [FilterType.GREATER_THAN, 0],
          [FilterType.LESS_THAN, 20]
        )
      }
    ],
    [firstOptionLabelKey, t]
  );
}
