import { Control, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SwitchControlled } from '~/components/Form/SwitchControlled';
import { withPermissions } from '~/components/PermissionChecker';
import { OrderState } from '~/constants/OrderState';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';

import { OrderOverviewSearchModel } from '../types';

import { FieldCol } from './FieldCol';

interface Props {
  control: Control<OrderOverviewSearchModel>;
  refetch: VoidFunction;
  setValue: UseFormSetValue<OrderOverviewSearchModel>;
}

export const OnlyShowActive = withPermissions<Props>(
  PERMISSION.CUSTOMER_LEAD_ASSIGNABLE,
  DOMAIN.CLM_GRANT
)(({ control, refetch, setValue }) => {
  const { t } = useTranslation();

  function onChange(value: boolean) {
    if (value) {
      setValue('state', [OrderState.PENDING_VERIFICATION]);
    } else {
      setValue('state', []);
      setValue('isOnlyShowActive', null);
    }

    refetch();
  }

  return (
    <FieldCol>
      <SwitchControlled
        label={t('bo.orderOverview.searchForm.field.isOnlyShowActive')}
        onChange={onChange}
        controllerProps={{
          name: 'isOnlyShowActive',
          control
        }}
      />
    </FieldCol>
  );
});
