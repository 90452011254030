import { ImageProjection } from '~/apollo/gql-types';
import { DocumentModel } from '~/components/DocumentViewer/types';

export function getUniqueValues<T extends Record<string, unknown>>(
  value: Record<string, T>
) {
  const entries = Object.entries<T>((value as Record<string, T>) ?? {});
  const [firstEntry] = entries;

  if (!firstEntry) {
    return [];
  }

  const [firstEntryKey, firstEntryValue] = firstEntry;

  const map = new Map<string, T>();

  map.set(firstEntryKey, firstEntryValue);

  entries.forEach(([key, entryA]) => {
    if (
      Array.from(map.values()).some(
        (entryB) => JSON.stringify(entryA) !== JSON.stringify(entryB)
      )
    ) {
      map.set(key, entryA);
    }
  });

  return map.size > 1 ? Object.entries(value) : Array.from(map.entries());
}

export function mapImageProjectionToDocumentEntity({
  id,
  ...props
}: ImageProjection): DocumentModel {
  const fullUrl = props.fullUrl.replace('{size}', '');

  return {
    id,
    fullUrl,
    fileName: fullUrl.substring(fullUrl.lastIndexOf('/') + 1)
  };
}
