import { TableCell, formatCurrency } from '@retail/backoffice-ui';
import { Button, TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RetailAdSecondaryWheelsProjection,
  RetailFinancePriceProjectionInput
} from '~/apollo/gql-types';
import { DocumentViewer } from '~/components/DocumentViewer';

import { Rims } from './Rims';
import { Tires } from './Tires';
import { mapImageProjectionToDocumentEntity } from './utils';

export function useColumns() {
  const { t } = useTranslation();

  return useMemo(
    (): TableColumnsType<RetailAdSecondaryWheelsProjection> => [
      {
        dataIndex: 'rimsDetails',
        title: (
          <strong>
            {t(
              'bo.summaryVerificationSecondaryWheelsUpsell.table.column.rimsDetails'
            )}
          </strong>
        ),
        render: (value) => (
          <TableCell data-qa-selector="rimsDetails">
            {Object.keys(value).length ? <Rims value={value} /> : null}
          </TableCell>
        )
      },
      {
        dataIndex: 'tiresDetails',
        title: (
          <strong>
            {t(
              'bo.summaryVerificationSecondaryWheelsUpsell.table.column.tiresDetails'
            )}
          </strong>
        ),
        render: (value) => (
          <TableCell data-qa-selector="tiresDetails">
            {Object.keys(value).length ? <Tires value={value} /> : null}
          </TableCell>
        )
      },
      {
        dataIndex: 'damageCondition',
        title: (
          <strong>
            {t(
              'bo.summaryVerificationSecondaryWheelsUpsell.table.column.damageCondition'
            )}
          </strong>
        ),
        render: (
          value,
          { adSecondaryWheelsDamagesImages, adSecondaryWheelsImages }
        ) => (
          <TableCell data-qa-selector="damageCondition">
            {value ? (
              <DocumentViewer
                documents={adSecondaryWheelsImages
                  .concat(...adSecondaryWheelsDamagesImages)
                  .map(mapImageProjectionToDocumentEntity)}
              >
                <Button size="small" type="link">
                  {t(
                    `bo.summaryVerificationSecondaryWheelsUpsell.table.column.damageCondition.${value}`
                  )}
                </Button>
              </DocumentViewer>
            ) : null}
          </TableCell>
        )
      },
      {
        dataIndex: 'invoiceGrossPrice',
        title: (
          <strong>
            {t('bo.summaryVerificationWarrantyUpsell.table.column.price')}
          </strong>
        ),
        render: ({
          amountMinorUnits,
          currencyCode
        }: RetailFinancePriceProjectionInput) => (
          <TableCell data-qa-selector="price">
            {formatCurrency({
              amountMinorUnits,
              currencyCode
            })}
          </TableCell>
        )
      }
    ],
    [t]
  );
}
