import { PlusOutlined } from '@ant-design/icons';
import { dispatchEvent } from '@retail/backoffice-events';
import { Button, Popconfirm, notification } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import { useTranslation } from 'react-i18next';

import { useUpsellWarrantyMutation } from '~/apollo/gql-types';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { useCheckPermissions } from '~/hooks/useCheckPermissions';

interface Props {
  orderId?: string;
  warrantyId?: string;
}

export function ActionButton({ orderId, warrantyId }: Props) {
  const { t } = useTranslation();
  const [onSubmit, { loading }] = useUpsellWarrantyMutation({
    variables: {
      orderId,
      warrantyId
    },
    onCompleted() {
      notification.success({
        message: t(
          'bo.summaryVerificationWarrantyUpsell.table.submit.successMessage'
        )
      });
      dispatchEvent('orderVerification.warrantyUpsell.updated', null);
    },
    refetchQueries: ['WarrantyUpsellOptions'],
    awaitRefetchQueries: true
  });
  const { isAllowed } = useCheckPermissions({
    allow: [PERMISSION.UPSELL_WARRANTY, PERMISSION.UPSELL_WARRANTY_FORCE],
    domain: DOMAIN.ORDER_MANAGEMENT
  });

  return isAllowed ? (
    <Popconfirm
      placement="top"
      title={t(
        'bo.summaryVerificationWarrantyUpsell.table.confirmation.message'
      )}
      disabled={!warrantyId}
      onConfirm={() => onSubmit()}
      okText={t('bo.common.yes')}
      cancelText={t('bo.common.no')}
      okButtonProps={
        {
          ['data-qa-selector']: 'okUpsellWarrantyButton'
        } as BaseButtonProps
      }
    >
      <Button
        type="primary"
        disabled={!warrantyId}
        loading={loading}
        data-qa-selector="upsellWarrantyButton"
        icon={<PlusOutlined />}
      >
        {t('bo.summaryVerificationWarrantyUpsell.table.submit')}
      </Button>
    </Popconfirm>
  ) : null;
}
