import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import './setup';
import Root from './root.component';
import { VerificationTabContent } from './features/VerificationTabContent';

function errorBoundary() {
  // Customize the root error boundary for your microfrontend here.
  return null;
}

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary
});

export const verificationTabContent = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: VerificationTabContent,
  errorBoundary
});
