import { SelectControlled } from '@retail/backoffice-ui';
import { UseControllerProps } from 'react-hook-form';

import { useConfirmOptions } from '../hooks/useConfirmOptions';
import { OrderOverviewSearchModel } from '../types';

import { FieldCol } from './FieldCol';

interface Props {
  qaSelector: string;
  label: string;
  controllerProps: UseControllerProps<OrderOverviewSearchModel>;
}

export function SelectConfirmation({
  qaSelector,
  label,
  controllerProps
}: Props) {
  const confirmOptions = useConfirmOptions();

  return (
    <FieldCol>
      <SelectControlled
        allowClear
        qaSelector={qaSelector}
        label={label}
        options={confirmOptions}
        controllerProps={controllerProps}
      />
    </FieldCol>
  );
}
