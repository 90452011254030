// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PWbRzQ1GAlYO5NETaZ7k {\n  display: flex;\n  gap: 8px;\n}\n._2iDb7uzAX11JAFU__Vg {\n  cursor: grab !important;\n}\n.rQLQQZA_ihjNC6o6vOaB {\n  background: #f5f5f5;\n  border: 1px solid #ebebeb;\n  z-index: 9999;\n  font-weight: 600;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.rQLQQZA_ihjNC6o6vOaB .hVmfaNP7Ke6aQ2UP38pQ {\n  width: 100px;\n}\n.rQLQQZA_ihjNC6o6vOaB td {\n  padding: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OrderOverview/ColumnSettingButton/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;AACF;AAEA;EACE,uBAAA;AAAF;AAGA;EACE,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AANA;EAUI,YAAA;AADJ;AAKA;EACE,YAAA;AAHF","sourcesContent":[".fieldNameColumn {\n  display: flex;\n  gap: 8px;\n}\n\n.fieldNameColumnButton {\n  cursor: grab !important;\n}\n\n.rowDragging {\n  background: rgb(245, 245, 245);\n  border: 1px solid rgb(235, 235, 235);\n  z-index: 9999;\n  font-weight: 600;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  .cellIsVisible {\n    width: 100px;\n  }\n}\n\n.rowDragging td {\n  padding: 8px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldNameColumn": "PWbRzQ1GAlYO5NETaZ7k",
	"fieldNameColumnButton": "_2iDb7uzAX11JAFU__Vg",
	"rowDragging": "rQLQQZA_ihjNC6o6vOaB",
	"cellIsVisible": "hVmfaNP7Ke6aQ2UP38pQ"
};
export default ___CSS_LOADER_EXPORT___;
