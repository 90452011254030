import { useTranslation } from 'react-i18next';

import { getUniqueValues } from './utils';

interface Props {
  value?: Record<string, Record<string, unknown>>;
}

export function Rims({ value }: Props) {
  const { t } = useTranslation();
  const uniqueValues = getUniqueValues(value);

  return (
    <>
      {uniqueValues.map(([key, { radius, manufacturer, material }]) => (
        <div key={key}>
          {`${
            uniqueValues.length > 1
              ? `${t(
                  `bo.summaryVerificationSecondaryWheelsUpsell.table.column.key.${key}`
                )}: `
              : ''
          }${[
            `R${radius}`,
            manufacturer,
            material
              ? t(
                  `bo.summaryVerificationSecondaryWheelsUpsell.table.column.rimsDetails.material.${material}`
                )
              : null
          ]
            .filter(Boolean)
            .join('. ')}`}
        </div>
      ))}
    </>
  );
}
