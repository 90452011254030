import { FormItem } from '@retail/backoffice-ui';
import { ColProps } from 'antd';
import generatePicker, {
  RangePickerDateProps
} from 'antd/lib/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { memo, ReactNode, useCallback } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';

import { toDate } from '~/utils/date';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

const { RangePicker } = DatePicker;

type InputControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  dateFormat: string;
  qaSelector?: string;
  label?: ReactNode;
  labelCol?: ColProps;
  required?: boolean;
  disabled?: boolean;
} & RangePickerDateProps<Date>;

export const DatePickerRangeControlled = memo<
  InputControlledProps<FieldValues>
>(
  ({
    required,
    disabled,
    label,
    labelCol,
    controllerProps,
    dateFormat,
    qaSelector,
    ...restInputProps
  }) => {
    const { field } = useController(controllerProps);
    const handleChange = useCallback(
      (values) => field.onChange(values),
      [field]
    );

    const value = Array.isArray(field.value)
      ? field.value.map(toDate)
      : field.value;

    return (
      <FormItem
        label={label}
        labelCol={labelCol}
        required={required}
        disabled={disabled}
        controllerProps={controllerProps}
      >
        <RangePicker
          {...field}
          value={value}
          disabled={disabled}
          format={dateFormat}
          onChange={handleChange}
          popupStyle={{ zIndex: 9999 }}
          data-qa-selector={qaSelector}
          dropdownClassName={`${qaSelector}-dropdown`}
          {...restInputProps}
        />
      </FormItem>
    );
  }
);
